$(window).on("load", function () {
// Modal Trigger
 
	var logoOriginal = '' ;
	var logoClass = '' ;
	var logoColour = '';

	function openModal(){

		logoOriginal = $('header .branding a object').attr('data');
		logoClass = $('header .branding').attr('class');
		logoColour = logoPathColour();
		
		logoSwitch('/assets/images/logo-inner.svg')

		window.setTimeout(function(){ $('#modal').show(); }, 0);
		window.setTimeout(function(){ $('body').removeClass('modal-closed'); }, 0);
		window.setTimeout(function(){ $('#modal').removeClass('modal-closed'); }, 0);
		window.setTimeout(function(){ $('body').addClass('modal-open'); }, 0);
		window.setTimeout(function(){ $('#modal').addClass('modal-open'); }, 0);

		window.setTimeout(function(){ $('#modal').css({
			"transform" : "translate(0,0)" ,
			"opacity" : "1"
		}); }, 250);

		

		if ($("body").hasClass("home") == true) {
			$('header .branding').removeClass('trans') ;
			$.scrollify.disable();
		} ;

		

	}

	$('.modal-trigger').on('click',function(e){
		
		e.preventDefault();
		e.stopPropagation();
		triggerModal($(this));
		
		return false ;

	});

	function logoPathColour() {

			var black = $('header .branding a object').hasClass('inverted');
			var colour = "black";
			if (!black) {
				colour = "white";
			}

			return colour;
	}


	function logoSwitch(path){

		if (!path) {
			path = logoOriginal;
		}

		$('header .branding').addClass('trans') ;
		$('header .branding object').attr('data' , path ) ;
		$('header .branding').removeClass('trans') ;
		$('header .branding').addClass(logoClass)
		$('header .branding').addClass('logo-pulled'); 
		window.setTimeout(function(){ $('header .branding').removeClass('logo-pulled');}, 1200);
	}

	function loadModalContent(url) {
		$.get(
			 url,
			 function (data) {
			 	$('.branding').removeClass('trans');
			 	$('.modal-content').html(data);
			 	startCalendar();
				startSliders();
				loadSelects();

				$("#modal section > *:not(.card)").each(function(index , element) {
					// $(this).delay(500*index).css({"opacity":"1"});
					
					$(this).css({"opacity":"1"});
				});

				window.setTimeout(function(){
					$('#modal .card').css({
						// "transform" : "translate(0,0)" ,
						"opacity" : "1" ,
						"width": "auto" ,
					}); 
				}, 500);

				window.setTimeout(function(){$('#modal  .card * ').css({
					// "transform" : "translate(0,0)" ,
					"opacity" : "1"
				}); }, 1000);	

				$('.modal-content').scrollTop(0);

				// $('.lightbox').click(function() {
				// 	alert('Hello');
				// });

				$('.lightbox').magnificPopup({
					type: 'image',
					closeOnContentClick: false,
					closeBtnInside: false,
					gallery: {
						enabled: true,
						navigateByImgClick: true,
						arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
						tPrev: 'Previous',
						tNext: 'Next',
					},
					image: {
						// titleSrc: 'caption',
						titleSrc: function(item) {
			            	return `
								<h1 class="mfc-image-title">${item.el.attr('mfc-title')}</h1>
								<p class="mfc-image-caption">${item.el.attr('mfc-caption')}</p>
			            	`
			            },
						verticalFit: true,
						markup: `
							<div class="mfp-figure">
								<div class="mfp-counter"></div>
								<div class="mfp-img"></div>
								<div class="mfp-bottom-bar">
									<div class="mfp-title"></div>
								</div>
							</div>
						`
					},
					retina: {
						ratio: 1,
						replaceSrc: function(itesm, ratio) {
							return item.src.replace(/\.\w+$/, function(m) { return '@2x' + m; });
						}
					}

				}).click(function() {
					$('.mfp-img').attr('alt', $(this).data('alt'));
				});
			 }
		);
	}

	function triggerModal(link) {

		var url = link.attr('href');
		window.history.pushState("", link.text(),  url);
		prevState = document.location;
		openModal();
		loadModalContent(url);
		

		if ($('body').hasClass('menu-open')) {
			$( '.menu-nav-trigger' ).trigger('click');
		}

	}

	var closing = false;
	

	$(window).bind('popstate', function(event){

	 	var currentState = event.target.location;

	 	if (currentState.hash.length > 0) {
	 		//Do nothing we are going to a hash page
	 	}
	 	else if (!currentState.pathname.startsWith('/home')) {

	 		$('modal-content').animate({
		        scrollTop: 0
		    }, 2000);
	 	}
	 	else {
	 		if ($('.modal-open').length > 0) {
				closeModal();
			}
	 	}
		
	});


	// Close Modal Trigger

	function closeModal(){

		if (closing == true) {
			return;
		}

		closing = true;

		// $('#modal').slideDown() ;
		var colour = logoPathColour();


		window.setTimeout(function(){ $('body').removeClass('modal-open'); }, 0);
		window.setTimeout(function(){ $('#modal').removeClass('modal-open'); }, 0);
		window.setTimeout(function(){ $('body').addClass('modal-closed'); }, 0);
		window.setTimeout(function(){ $('#modal').addClass('modal-closed'); }, 0);
		logoSwitch(false);


		

		window.setTimeout(function(){ $('#modal').css({
			"transform" : "translate(-100vw,0)" , 
			"opacity" : "1"
		}); }, 0);

		window.setTimeout(function(){ $('#modal').hide(); }, 1250);
		
		window.setTimeout(function(){ $('#modal').css({
			"transform" : "translate(0,100vh)" ,
			"opacity" : "1"
		}); }, 1600);

		window.setTimeout(function(){ 
			$("#modal section > *").each(function(index) {
			    // $(this).delay(0*index).css({"opacity":"0"});
			    $(this).css({"opacity":"0"});
			});
			
		 }, 2400);

		window.setTimeout(function(){ $('.card  ').css({
			// "transform" : "translate(0,0)" ,
			"opacity" : "0",
			"width": "20px" ,
			"max-width": "20px"
		}); }, 1500);

		window.setTimeout(function(){ $('.card * ').css({
			// "transform" : "translate(0,0)" ,
			"opacity" : "0" ,
			 
			"max-width" : "auto" 
		}); }, 1500);



		if (colour == "black") {
			if ($(window).width() > 768) {
				$("#logo").attr("data", '/assets/images/logo-inverted.svg');
				$("#logoInstagram").attr("data", '/assets/images/icon-instagram.svg');
				$("#logoFacebook").attr("data", '/assets/images/icon-fb.svg');
				$("#logoTripAdvisor").attr("data", '/assets/images/icon-tripadvisor.svg');
				$('.thermometer , .pagination').addClass('inverted');
			}
			else {
				$("#logo").attr("data", '/assets/images/logo-inner-black.svg');
			}
		}


		if ($("body").hasClass("home") == true) {
			$.scrollify.enable();
			
		}
		$(".booking-toggle").toggle();
		window.setTimeout(function(){
			if (window.scrollY !== 0) {
				$('header li.branding.show-on-close').removeClass('trans show-on-close');
			} 

		}, 1500);
		
		closing = false;
	}

	$(document).on('click', 'body.modal-open header li.close a' , function(e){

		e.preventDefault();
		window.history.back();
		closeModal();
		
		return false ;
	});




	// Mobile dropdown menu
	if ($(window).width() < 768) {
		$('.menu ul > li:not(.secondary)').prepend('<span class="arrow"></span>');
	}

	$(document).on('click', '.menu ul > li:not(.secondary)' , function(event){


		if ($(window).width() < 768) {

			event.preventDefault();

			var parent = $(this).parent();

			$(parent).toggleClass('open') ;

			$(parent).find('.secondary').slideToggle();

			$(parent).parent().find('.social-link').toggleClass('visible').css({"display" : "inline-block"} ) ;

			$('header li.branding.show-on-close, header .close , header .social').removeClass('trans');
		}
	});

	// Close menu and scroll when item is clicked


	$(document).on('click', '.menu ul > li:not(.secondary) > a' , function(event){

		if ($(window).width() < 768) {

			event.preventDefault();
			var parent = $(this).parent().parent();

			if ( $(parent).hasClass('open') ) {
		 		window.location = $(this).attr('href');
		 		$('.full-menu').toggleClass('open');
				$('body').toggleClass('menu-open');
				$('.nav .menu-nav-icon ').toggleClass('is-open');
		 	} 

		} ;

	});



	$(document).on('click', '.menu ul > li.secondary > a' , function(event){

		var elem = event.target  ;

		$('.full-menu').removeClass('open');
		$('body').removeClass('menu-open');
		$('.nav .menu-nav-icon ').removeClass('is-open');
		$('header li.branding.show-on-close, header .close , header .social').removeClass('trans');

		if ( $(elem).hasClass('modal-trigger') ) {

			event.preventDefault();
		}
		else {
			var id = $(elem).attr('href');
			var id = id.replace('/#', '');
			var target = $( id ).offset().top + 1 ;
		}


	});

});



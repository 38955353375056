var unavailableDates = new Array();

if ($('.reservation-summary select').length > 0) {
	/*$('.reservation-summary select').select2({
		  selectOnClose: true ,
		  width: "100%",
		  minimumResultsForSearch: Infinity ,
		}).data('select2').$dropdown.addClass('sidebarred');*/
}

// Open the form

async function openBookingForm(elem, button) {
console.log("open form");
	$('div.availability-legend').remove();
	$('div.room-type-refiner').remove();
		var init_apiKey = "ebc5c39f-3fb4-47d1-b142-523f8881fee2";
		var init_apiUrl = "https://sydney.protel.net";
		var protelURL = init_apiUrl + "/WBE/1/";
		var $roomTypeSelector = $('<div class="room-type-refiner"><p>SELECTED ROOM TYPES</p><div class="refiner-row"></div></div>');
		var thisRoomTypeID;
		if ($('#protelId')) {			
			thisRoomTypeID = $('#protelId').val();
			console.log(thisRoomTypeID);
		}
	await makeGetRequest(protelURL + init_apiKey + "/RoomTypes/WBEList?format=json").then(
	function(roomTypes){
		
		var filteredRoomTypes = roomTypes.filter(rt => rt.ids != 1054);
		console.log(filteredRoomTypes);
		$(elem).addClass('open'); 
		$(elem).removeClass('closed');
		$('.booking-form').css( {'visibility' : ''} );
		
		$(button).addClass('form-open');
		$("#dateTo").val("");
	    $("#dateToYear").val("");
	    $("#dateFrom").val("");
	    $("#dateFromYear").val("");
		
		$('.comiseo-daterangepicker-triggerbutton').trigger('click').hide();
		$('td[data-handler="selectDay"]').each(function(e){
			$(this).removeClass("available").removeClass("not-available").removeClass("low-availability ").removeClass("dp-highlight").removeClass("ui-state-highlight");
		});
		filteredRoomTypes.sort(compareIds).slice(0).forEach(function(rt){
			if (rt.ids == thisRoomTypeID || !thisRoomTypeID) {
				$roomTypeSelector.find('.refiner-row').append("<a style='' class='button selected room-type-selector' data-roomTypeID='"+ rt.ids +"'>" + rt.name + "</a>");
			} else {
				$roomTypeSelector.find('.refiner-row').append("<a style='' class='button room-type-selector' data-roomTypeID='"+ rt.ids +"'>" + rt.name + "</a>");
			}
			
		});
		
		$roomTypeSelector.appendTo('.comiseo-daterangepicker');
		$('<div class="availability-legend"><p>Select your preferred date range then click <span style="font-family:AvenirNextLTPro-Regular, sans-serif">CHECK AVAILABILITY</span> to see indicative availability.</p></div>').appendTo('.comiseo-daterangepicker');

		$(".room-type-selector").on("click",function(){
			console.log("clicked");
			$(this).toggleClass('selected');
		})

	}
	);

	
	//$('.comiseo-daterangepicker').removeClass("temp-hidden"); 
}

function compareIds(a, b) {
	// Use toUpperCase() to ignore character casing
	var A = parseInt(a.ids);
	var B = parseInt(b.ids);
	let comparison = 0;
	if (A > B) {
	  comparison = 1;
	} else if (A < B) {
	  comparison = -1;
	}
	return comparison;
  }
// close the form

function closeBookingForm(elem , button) {
		
	$(elem).removeClass('open'); 
	$(elem).addClass('closed');
	$(button).removeClass('form-open');

	//$('.comiseo-daterangepicker').addClass("temp-hidden");
	$("#bar-date").daterangepicker("close");
	$(button).find("span").removeClass('loading');
	$(button).find("span").text('Check Availability');
	$(button).removeClass('disabled');
	$('div.availability-legend').remove();
	$('div.room-type-refiner').remove();
}

function getDateDetails() {
	var dateFormat = "D dd MM yy";
	var dateFrom = "";
	var dateTo = "";
	if ($("#dateFrom").val() != "") {
		dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
	}
	if ($("#dateTo").val() != "") {
		dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
	}
	else {
		dateTo = dateFrom;
	}

	var details = {dateFrom: dateFrom.toString(), dateTo:dateTo.toString() };

	return details;
}

function parseURLDate(date){
	return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
}

function loadCountrySelectFields() {
	$(".telephone").intlTelInput({
		// options here
		allowDropdown:true,
		initialCountry:"au",
		placeholderNumberType:"MOBILE",
		preferredCountries: ["au","nz","us","gb"],
		separateDialCode:true,
	  });

	  if ($(".telephone").length){
		  console.log("found telephone");

			document.getElementById("booker-telephone").addEventListener("countrychange",function() {
			$('label.same-booker.selected-child').each(function(){
				//$(this).trigger('click');
				var guestFields = $(this).parent('.booked-room').next('.guest-fields');
				
					var countryData = $("#booker-telephone").intlTelInput("getSelectedCountryData");
					guestFields.find('input.telephone').intlTelInput('setCountry',countryData.iso2);
					guestFields.find('input[name^=guest-email]').val($('input[name=email]'));
					guestFields.find('select[name^=guest-salutation]').val($('select[name=salutation]').val()).prev('#placeholder').addClass('active-focus-filled');
					guestFields.find('input[name^=guest-firstname]').val($('input[name=firstname]').val()).prev('#placeholder').addClass('active-focus-filled');
					guestFields.find('input[name^=guest-lastname]').val($('input[name=lastname]').val()).prev('#placeholder').addClass('active-focus-filled');
					guestFields.find('input[name^=guest-email]').val($('input[name=email]').val()).prev('#placeholder').addClass('active-focus-filled');
					guestFields.find('input[name^=guest-mobile]').val($('input[name=mobile]').val()).prev('#placeholder').addClass('active-focus-filled');
					//guestFields.find(' input[name^=guest-country]').val($('input[name=country]').val());
					var country = $('input[name=country]').val();
					guestFields.find('input.country').countrySelect("setCountry", country);
					guestFields.find('input[name^=guest_country_code]').val($('input#country_tel').val()).prev('#placeholder').addClass('active-focus-filled');

			});
		})
	  }


	$('input.country').each( function(){

		$(this).countrySelect({
			defaultCountry: "au",
			//onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
			preferredCountries: ['au', 'nz', 'gb', 'us']
		});

	})

}

async function processRoomTypeAvailability(details,source){
    //var selector  = 'td.dp-highlight[data-handler="selectDay"]';
console.log(apiKey);	
	$(".room-type-selector").removeClass("not-available").removeClass("available").removeClass("low-availability");
	$(".room-type-selector").addClass("initial");

	if (details.dateFrom != details.dateTo) {

		$(".field-group.date-info").show();
		$(".calendar-select.instruction").hide();
		//$('.checking.instruction').css('display','table');
		$('.calendar .checking-container').show();
		$('.room-type-refiner p').text('Room Types');
		$('.no-availability.instruction').hide();
		$('.reselect-dates').hide();


		var init_apiKey = apiKey;
		var init_apiUrl = "https://sydney.protel.net";
		var protelURL = init_apiUrl + "/WBE/1/";

		var dateFrom = new Date(details.dateFrom);
		var urlDateFrom = dateFrom.getFullYear() + "-" + (dateFrom.getMonth()+1) + "-" + dateFrom.getDate();

		var dateTo = new Date(details.dateTo);
		var urlDateTo = dateTo.getFullYear() + "-" + (dateTo.getMonth()+1) + "-" + dateTo.getDate();

		let options = { year: 'numeric', month: 'long', day: 'numeric' };
		var displayDateFrom = dateFrom.toLocaleDateString('en-au', options);
		var displayDateTo = dateTo.toLocaleDateString('en-au', options);
		

		

		var rooms = await makeGetRequest(protelURL + init_apiKey + "/rates?format=json&isocode=en&type=standardroom&from=" + urlDateFrom + "&to=" + urlDateTo + "&countrooms=1" + "&occupancy=2,0,0,0,0,0,0");
	//console.log(protelURL + init_apiKey + "/rates?format=json&isocode=en&type=standardroom&from=" + urlDateFrom + "&to=" + urlDateTo + "&countrooms=1" + "&occupancy=2,0,0,0,0,0,0");
		//console.log(rooms);
		rooms.instances.forEach(function(i){
console.log(rooms);

				if (i.error || i.roomTypes.length === 0) {
					console.log("array is empty");
						//no room types found, so ALL room types not available
						console.log("show error message");
						$(".room-type-selector").addClass("not-available");
						$('a.button.dates-continue').hide();
						
						$('.no-availability.instruction').show();
						$('.reselect-dates').show();
						//target = $('.current-step .no-availability:eq(1)');
						$('.reselect-dates .button').on('click',function(e){
							target = $('.date-info');
							if (target.length) {
							$('html,body').animate({
								scrollTop: target.offset().top
							}, 1000);
							return false;
							}
						});
				} else {
					i.roomTypes.forEach(function(r){
						//console.log(r);
						var $element = $('.room-type-selector[data-roomtypeid="'+r.id+'"]');
						//console.log($element);
						if (r.freeRoomsCount == 1){
							//console.log("low-availability");
							$element.addClass("low-availability").removeClass("initial");
						} else {
							//console.log("available");
							$element.addClass("available").removeClass("initial");
						}
					});
					$('.room-type-selector.initial').addClass('not-available');
					$('a.button.dates-continue').show();
				}
			
			

		});

		//$('.checking.instruction').hide();
		$('.calendar .checking-container').hide();
		$('.room-type-refiner p').text('RESULTS FOR:  ' + displayDateFrom + ' - ' + displayDateTo);

		//scroll to results
		console.log(source);
		if (source == "calendar") {
			var target = $('.room-type-refiner');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
				}
		} else {
			var target = $('.date-info');	
		}
		
		
		//console.log( target.offset());
		
		
		

	}
	
}

function processUnavailableDates(dates) {

var dateFrom = new Date(dates.dateFrom);
var dayFrom = dateFrom.getDate();
console.log(dateFrom.getDate());
var calendarElement = $('.hasDatepicker');
console.log(calendarElement.children().children());
	/*for (var date in dates) {
		var dayElements = dates[date].split("-");

		var month = dayElements[1] - 1;
		var year = dayElements[0];
		var day = dayElements[2].replace(/^0+/, '');

		var calendarElement = $('.hasDatepicker').find('td[data-month='+month+'][data-year='+year+'] a').filter(function () {
		    return $(this).text() == day;
		});

		if (calendarElement.parent().hasClass('dp-highlight')) {
			addWarning("These Dates Are Unavailable ", "Please select alternative dates");
		}
		else {
			removeWarnings();
		}
		calendarElement.parent().addClass('ui-datepicker-unselectable ui-state-disabled unavailable');
		calendarElement.replaceWith($('<span class="ui-state-default">' + calendarElement.text() + '</span>'));

		
	}*/
}

function getUnavailableDates (dateFrom, dateTo,$cell) {
	//Do an ajax query that runs in the background (because its too slow to do other wise) 
	//that get the current 2 months we are on dates from Protel and returns an array of any unavailable dates
	/*if ($('p.booking-bar-unavailable .button').length > 0) {
		$('p.booking-bar-unavailable .button').text('Checking...');
	}*/
	

	//processUnavailableDates(unavailableDates);
	return promise = new Promise(function (resolve, reject) {
	var attributes = {};

	attributes.dateFrom = dateFrom;
	attributes.dateTo = dateTo;

	var data = {
	    action: 'protel/default/unavailable-dates',
	    attributes: attributes
	};

	data[window.csrfTokenName] = window.csrfTokenValue;
	console.log(data);
 	var currentRequest = null; 
	currentRequest = $.ajax({
		type: "post",
		url: "",
		data: data,
		beforeSend : function()    {  

	        if(currentRequest != null) {
	            currentRequest.abort();
				console.log('request aborted');
	        }
	    },
	    success: function (response) {
			console.log(response);
	    	if (response != false) {

	    		var dates = JSON.parse(response);
				/*if (dates.success){
					if (dates.data.instances[0].roomTypes) {
						if (dates.data.instances[0].roomTypes.length == 1) {
							$cell.addClass("available low-availability");
						} else {
							$cell.addClass("available");
						}
						
					} else {
						$cell.addClass("not-available");
					}
				}*/
	    		resolve(dates.data);
	    	}
	    	
	    },
	    error: function (XMLHttpRequest, textStatus) {
	    		if ($('p.booking-bar-unavailable .button').length > 0) {
					$('p.booking-bar-unavailable .button').text('Check Availability');
				}
		       console.log(textStatus);
			   reject("failed");
	    }
	});
	//return $cell;
});
}

$(document).on('click', 'a.button.availability-check' , function(e){
	console.log(e);
	//processAvailability($('#dateFrom').val(),$('#dateTo').val());
});

function httpGet(theUrl)
{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
    xmlHttp.send( null );
    return xmlHttp.responseText;
}

function httpGetAsync(theUrl, callback)
{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function() { 
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
            callback(xmlHttp.responseText);
    }
    xmlHttp.open("GET", theUrl, true); // true for asynchronous 
    xmlHttp.send(null);
}

function startCalendar(selector) {

	//Use dateFrom if set otherwise Today
	var defaultDate = new Date();
	if ($("#dateFrom").length != 0 && $("#dateFrom").val() !== "") {
		defaultDate = $.datepicker.parseDate("D dd MM yy", $("#dateFrom").val() + " " + $("#dateFromYear").val())
	}

	//Do not allow bookings for today after 5pm
	let currentDate = new Date();
	let minDateSetting = (currentDate.getHours() > 17) ? 1: 0;
	console.log(currentDate.getHours());

	$(selector).datepicker({
		// dateFormat: "dd-mm" ,
		dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		minDate: minDateSetting,
		numberOfMonths: [2,1],
		beforeShowDay: function(date) {
			var dateFormat = "D dd MM yy";
			var dateFrom = "";
			var dateTo = "";

			if ($("#dateFrom").val() != "") {
			
				dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
			}
			if ($("#dateTo").val() != "") {
				dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
			}

			var numNights = "";

			var highlightClass = dateFrom && ((date.getTime() == dateFrom.getTime()) || (dateTo && date >= dateFrom && date <= dateTo)) ? "dp-highlight" : "";

			if (dateFrom && dateTo) {
				//Can only work out number of nights if we have both a to and from date
				dateFromTime = dateFrom.getTime();
				dateToTime = dateTo.getTime();

				var dateDiff =  dateToTime - dateFromTime;
				var numDays = (dateDiff/(1000*60*60*24));

				if (numDays > 0) {
					numNights = numDays;
				}

				if (highlightClass == "dp-highlight") {

					//var availability = processAvailability(date);
					
					/*if (availability) {
						highlightClass = highlightClass + " available";
					} else {
						highlightClass = highlightClass + " not-available";
					}
					var init_apiKey = "ebc5c39f-3fb4-47d1-b142-523f8881fee2";
    				var init_apiUrl = "https://sydney.protel.net";
    				var protelURL = init_apiUrl + "/WBE/1/";
					var thisDateTo= new Date();
					thisDateTo.setDate(date.getDate()+1);
					thisDateTo.setMonth(date.getMonth()+1);
					
					var urlDateFrom = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
					var urlDateTo = thisDateTo.getFullYear() + "-" + thisDateTo.getMonth() + "-" + thisDateTo.getDate();
					var url = protelURL + init_apiKey + "/rates?format=json&isocode=en&type=standardroom&from=" + urlDateFrom+ "&to=" + urlDateTo + "&countrooms=1";
					httpGetAsync(url,function(c){
						highlightClass = highlightClass + " available";
						var result = JSON.parse(c);
						console.log(result.data);
						
					});*/
				}

				
			}

			//TO DO add word depart to last date

			$('#nights').val(numNights);

			

			

			if (dateTo) {
				if (highlightClass === "dp-highlight" && dateTo.getTime() === date.getTime()) {
					//Add the depart class for the last day
					highlightClass = highlightClass + " depart";
					//$('a.button.availability-check').click();
					
				}
			}

			if (dateFrom) {
				if (highlightClass === "dp-highlight" && dateFrom.getTime() === date.getTime()) {
					//Add the depart class for the last day
					highlightClass = highlightClass + " arrive";
				}
			}

			

			return [true, highlightClass];
		},		
		onSelect: function(dateText, inst) {

			var selectedYear = inst.selectedYear;
			var dateFormat = "D dd MM yy";
			var dateFrom = "";
			var dateTo = "";

			

			
			if ($("#dateFrom").val() != "") {
				dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
			}
			if ($("#dateTo").val() != "") {
				dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
			}
			//var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $("#dateFrom").val());
			//var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $("#dateTo").val());
			var selectedDate = new Date(selectedYear, inst.selectedMonth, inst.selectedDay);

			if (!dateFrom || dateTo) {

				$("#dateFrom").val(dateText);
				$("#dateFromYear").val(selectedYear);

				$("#dateTo").val("");
				$(this).datepicker();

			} else if( selectedDate < dateFrom ) {

				$("#dateTo").val( $("#dateFrom").val() );
				$("#dateToYear").val($("#dateFromYear").val());
				$("#dateFrom").val( dateText );
				$("#dateFromYear").val(selectedYear);

				$(this).datepicker();

			} else {
				$("#dateTo").val(dateText);
				$("#dateToYear").val(selectedYear);
				$(inst).addClass('depart');
				$(this).datepicker();
				
			}

			
			updateBookingNav();
			var details = getDateDetails();
			updateBookingCookie(details, 'dates');
			processRoomTypeAvailability(details,"calendar");

		},
		onChangeMonthYear: function(year, month, inst){

			var dateFrom = moment(year+"-"+month+"-01", "YYYY-M-DD");
			var nextMonth = month+1;
			if (nextMonth > 12) {
				nextMonth = 1;
			}
			var dateTo = moment(year+"-"+nextMonth+"-01", "YYYY-M-DD");
			var lastDay = dateTo.daysInMonth();
			
			dateTo = moment(year+"-"+nextMonth+"-"+lastDay, "YYYY-M-DD");

			//getUnavailableDates(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"));
		}
		
	});

	var date = new Date(), y = date.getFullYear(), m = date.getMonth();
	var dy = defaultDate.getFullYear();
	var dm = defaultDate.getMonth();
	var firstDay = new Date(dy, dm, 1);
	var lastDay = new Date(dy, dm + 2, 0);

	if (defaultDate.getMonth() <= m) {
		firstDay = date;
		lastDay = new Date(y, m + 2, 0);
	}
	
	var dateFrom = moment(firstDay);
	var dateTo = moment(lastDay);


	//getUnavailableDates(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"));
	duplicateMonthArrows();
}

function duplicateMonthArrows() {
	var width = $( window ).width();

	if (width <= 1279) {
		$("#datepicker .ui-datepicker-prev").appendTo( ".ui-datepicker-header" );
		$("#datepicker .ui-datepicker-next").appendTo( ".ui-datepicker-header" );
	}

} 

function startRoomDateCheck(selector){
	
}


function startBookingBar() {
	// Booking Process - Date Picker
	
	var defaultDate = new Date();
	if ($("#dateFrom").val() !== "") {
		defaultDate = $.datepicker.parseDate("D dd MM yy", $("#dateFrom").val() + " " + $("#dateFromYear").val())
	}

	$("#bar-date").daterangepicker({
		initialText : 'Open Calendar',
		dateFormat: 'd M y',
		datepickerOptions : {
			dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			minDate: 0,
			maxDate: null,
			numberOfMonths: 2,
			beforeShowDay: function(date) { 
				var dateFormat = "D dd MM yy";
				var dateFrom = "";
				var dateTo = "";
				if ($("#dateFrom").val() != "") {
				
					dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
				}
				if ($("#dateTo").val() != "") {
					dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
				}

				var numNights = "";

				if (dateFrom && dateTo) {
					//Can only work out number of nights if we have both a to and from date
					dateFromTime = dateFrom.getTime();
					dateToTime = dateTo.getTime();

					var dateDiff =  dateToTime - dateFromTime;
					var numDays = (dateDiff/(1000*60*60*24));

					if (numDays > 0) {
						numNights = numDays;
					}
				}

				//TO DO add word depart to last date

				$('#nights').text(numNights);

				var highlightClass = dateFrom && ((date.getTime() == dateFrom.getTime()) || (dateTo && date >= dateFrom && date <= dateTo)) ? "dp-highlight" : "";

				if (dateTo) {
					if (highlightClass === "dp-highlight" && dateTo.getTime() === date.getTime()) {
						//Add the depart class for the last day
						highlightClass = highlightClass + " depart";
					}
				}

				if (dateFrom) {
					if (highlightClass === "dp-highlight" && dateFrom.getTime() === date.getTime()) {
						//Add the depart class for the last day
						highlightClass = highlightClass + " arrive";
					}
				}
				return [true, highlightClass];
			},
			onSelect: function(dateText, inst) {

				var selectedYear = inst.selectedYear;
				var dateFormat = "D dd MM yy";
				var dateFrom = "";
				var dateTo = "";

				if ($("#dateFrom").val() != "") {
					dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
				}
				if ($("#dateTo").val() != "") {
					dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
				}
				var selectedDate = new Date(selectedYear, inst.selectedMonth, inst.selectedDay);

				if (!dateFrom || dateTo) {

					$("#dateFrom").val(dateText);
					$("#dateFromYear").val(selectedYear);
					
					$("#dateTo").val("");

					redrawBarDate(selectedDate, false);

				} else if( selectedDate < dateFrom ) {

					$("#dateTo").val( $("#dateFrom").val() );
					$("#dateToYear").val($("#dateFromYear").val());
					$("#dateFrom").val( dateText );
					$("#dateFromYear").val(selectedYear);

					redrawBarDate(selectedDate, dateFrom);
					
				} else {
					$("#dateTo").val(dateText);
					$("#dateToYear").val(selectedYear);
					$(inst).addClass('depart');

					redrawBarDate(dateFrom, selectedDate);
				}
				var beginDateFromObject = $('.ui-datepicker-calendar:first').find(" tbody td[data-handler='selectDay']").first();
				var beginDateFromDay = beginDateFromObject.find('a').text();
				var beginDateFromMonth = parseInt(beginDateFromObject.attr('data-month')) + 1;
				var beginDateFromYear = beginDateFromObject.attr('data-year');

				var beginDateFrom = beginDateFromYear + "-" + beginDateFromMonth + "-" + beginDateFromDay;

				var beginDateToObject = $('.ui-datepicker-calendar:nth-child(2)').find(" tbody td[data-handler='selectDay']").last();
				var beginDateToDay = beginDateToObject.find('a').text();
				var beginDateToMonth = parseInt(beginDateToObject.attr('data-month')) + 1;
				var beginDateToYear = beginDateToObject.attr('data-year');


				var beginDateTo = beginDateToYear + "-" + beginDateToMonth + "-" + beginDateToDay;
				//getUnavailableDates(beginDateFrom, beginDateTo);
				removeWarnings();
				$("p.booking-bar-unavailable.warning").remove();
				//$("div.availability-legend").remove();
				var details = getDateDetails();
				details.rooms = {'adults':1,'children':0};
				updateBookingCookie(details, 'dates');
			},
			onChangeMonthYear: function(year, month, inst){

				var dateFrom = moment(year+"-"+month+"-01", "YYYY-M-DD");
				var nextMonth = month+1;
				if (nextMonth > 12) {
					nextMonth = 1;
				}
				var dateTo = moment(year+"-"+nextMonth+"-01", "YYYY-M-DD");
				var lastDay = dateTo.daysInMonth();
				
				dateTo = moment(year+"-"+nextMonth+"-"+lastDay, "YYYY-M-DD");
				removeWarnings()
				$("p.booking-bar-unavailable.warning").remove();
				$("div.availability-legend").html('<p>Select your preferred date range then click <span style="font-family:AvenirNextLTPro-Regular, sans-serif">CHECK AVAILABILITY</span> to see indicative availability.</p></div>');
				//$("div.availability-legend").remove();
				//getUnavailableDates(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"));
			}
		},
		applyOnMenuSelect: false,
		applyButtonText: "",
		clearButtonText: "",
		cancelButtonText: " "

	});



	var date = new Date(), y = date.getFullYear(), m = date.getMonth();
	var dy = defaultDate.getFullYear();
	var dm = defaultDate.getMonth();
	var firstDay = new Date(dy, dm, 1);
	var lastDay = new Date(dy, dm + 2, 0);

	if (defaultDate.getMonth() <= m) {
		firstDay = date;
		lastDay = new Date(y, m + 2, 0);
	}
	
	var dateFrom = moment(firstDay);
	var dateTo = moment(lastDay);
	$("p.booking-bar-unavailable.warning").remove();

	//getUnavailableDates(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"));
}


function redrawBarDate(start, end) {

	var displayString = moment(start).format("D MMM YY");
	if (end) {
		displayString = displayString + " - " + moment(end).format("D MMM YY");
	}
	$(".comiseo-daterangepicker-triggerbutton").text(displayString);

	var bookingRef = "?";
	if (start) {
		bookingRef = bookingRef + "start=" + moment(start).format("DDMMYYYY");
	}
	if (end && start) {
		bookingRef = bookingRef + "&end=" + moment(end).format("DDMMYYYY");
	} else if (end) {
		bookingRef = bookingRef + "end=" + moment(end).format("DDMMYYYY");
	}

	$('a.button.pre-select').attr('href',"/book/guests" + bookingRef);
}

// Update booking navbar.

function updateBookingNav() {

	// Map values to step .details

	// Collect the info for the menu tab details + payment
	
	var grandTotal = $('.total-grand').last().text()

	var bookingDetails = [];
	var bookingDates = "";
	
	bookingDetails["dates"] =  bookingDates ;

	bookingDetails["total"] = grandTotal ;


	if ($(".steps li[data-url='guests']").hasClass('current-step')) {
		//We are on the guests tab so update
		var bookingRooms = $('.room-amount .field-group-numerical').length ; 
	
		var adults  = 0;
		var children = 0;

		var bookingAdults = $("input.adults").each(function() { 
			adults += parseInt($(this).val(), 10)  ; 
		});


		var bookingChildren = $("input.children").each(function() { 
			children += parseInt($(this).val(), 10) ; 
		});

		bookingDetails["rooms"] =  bookingRooms ;
		bookingDetails["adults"] = bookingAdults ;
		bookingDetails["children"] = bookingChildren  ;
		bookingDetails["people"] = ( adults + children ) ;

		if (bookingDetails["people"] < 10) {
			bookingDetails["people"] = "0" + bookingDetails["people"];
		}

		$(".steps li[data-url='guests']").find(".detail").text(bookingDetails.people + '/' + bookingDetails.rooms);
	}

	if ($(".steps li[data-url='dates']").hasClass('current-step')) {

		//We are on the dates step so update
		var dateFrom = $('#dateFrom').val();
		var dateTo = $('#dateTo').val();

		var parsedDateTo = moment(new Date(dateTo)).format("D MMM");
		var parsedDateFrom = moment(new Date(dateFrom)).format("D MMM");

		if (moment(new Date(parsedDateTo)).isValid()) {
			var bookingDates =  parsedDateFrom + " - " + parsedDateTo ; 
		}
		else {
			var bookingDates =  parsedDateFrom;
		}

		$(".steps li[data-url='dates']").find(".detail").text(bookingDates);
	}

	if ($(".steps li[data-url='extras']").hasClass('current-step')) {
		/*if ($('.reservation-summary .room-extra').length > 1) {
			$(".steps li[data-url='extra']").find(".detail").text("Customised");
		}*/

	}

	edited = true;

}

function removeBookingCookieItem(type, item) {
	if (Cookies.get('bookingDetails')) {

		var existingDetails = Cookies.get('bookingDetails');
		existingDetails = JSON.parse(window.atob(existingDetails));


		if (existingDetails[type] != null) {
			if (item) {
				var index = existingDetails[type].indexOf(item);
				if (index > -1) {
					existingDetails[type].splice(index, 1);
				}
			}
			else {
				delete existingDetails[type];
			}
			
		}

		return existingDetails;
	}
}

function removeRoomBookingDetails(rooms) {

	if (rooms.length > 0) {

		for(i=0;i<rooms.length;i++) {
			delete rooms[i].roomType;
			delete rooms[i].roomNumber;
			delete rooms[i].roomTypeId;
			delete rooms[i].rateId;
			delete rooms[i].booked;
		}
	}

	return rooms;
}

var edited = false;

function updateBookingCookie(bookingDetails, tab) {
console.log("updating cookie from: " + tab);
console.log(bookingDetails);
	if (bookingDetails.rooms != null && bookingDetails.rooms.length > 0) {
		bookingDetails.rooms = bookingDetails.rooms.filter(function(e){return e}); 
	}

	var basketId = false;

	if (Cookies.get('bookingDetails')) {
		//There are already details so either merge or overwrite
		var existingDetails = Cookies.get('bookingDetails');
		existingDetails = JSON.parse(window.atob(existingDetails));
console.log(existingDetails);
		if (existingDetails.basketId != null) {
			basketId = true;
		}

		//We need to some validation here.
		//1.) If we are updating dates, check if there is a basket ID - if there is then remove all rooms and extras set.
		if (tab === 'dates' && basketId && edited) {

			existingDetails = removeBookingCookieItem("basketId", false);
			existingDetails.rooms = removeRoomBookingDetails(existingDetails.rooms);
		}
		
		
		//2.) If we are updating guests, check if there is a basket ID - if there is then remove all rooms and extras set.
		if (tab === 'guests' && basketId && edited) {
			existingDetails = removeBookingCookieItem("basketId", false);
		}

		//if there is already a room array set only merge if we have changed rooms.
		if (tab === 'extras') {
			//bookingDetails.extras = bookingDetails.extras.concat(existingDetails.extras);
			bookingDetails = $.extend({}, existingDetails, bookingDetails);
		}
		else {
			bookingDetails = $.extend({}, existingDetails, bookingDetails);
		}

		/*else if (tab == 'guests' ) {
			bookingDetails = $.extend({}, existingDetails, bookingDetails);
		}
		else if (tab === 'guests' && edited === true) {
			bookingDetails = $.extend({}, existingDetails, bookingDetails);
		}
		else if (tab === 'notes') {
			bookingDetails = $.extend({}, existingDetails, bookingDetails);
		}*/
		

	}
console.log(bookingDetails);
	saveEncodedCookie(bookingDetails);
	
	edited = false;
}


function saveEncodedCookie(bookingDetails) {

	if (bookingDetails == "") {
		return;
	}
	var json_details = JSON.stringify(bookingDetails);

	var encoded_details = window.btoa(json_details);

	Cookies.set('bookingDetails', encoded_details, { expires: 1 });
}

function getSavedRooms() {
	if (Cookies.get('bookingDetails')) {
		//There are already details so either merge or overwrite
		var existingDetails = Cookies.get('bookingDetails');
		existingDetails = JSON.parse(window.atob(existingDetails));
		return existingDetails.rooms;
	}

	return false;
}

function validateBasket() {
	var data = {
    	action: 'protel/default/check-basket'
	};

	data[window.csrfTokenName] = window.csrfTokenValue;

	$.ajax({
		type: "post",
		url: "",
		data: data,
	    success: function (response) {

	    	if (response == true) {
	    		return true;
	    	}
	    	else {
	    		if (response == false) {
	    			return false;
	    		}
	    		response = JSON.parse(response);
	    		//Clear the cookie and go back to guests
	    		setBookingError(response.message);
	    		resetBooking();
	    	}
	        
	    },
	    error: function (XMLHttpRequest, textStatus) {
	    	//Clear the cookie and go back to guests
    		Cookies.remove('bookingDetails');
    		if (!$(".steps li[data-url='guests']").hasClass('current-step')) {
    			window.location.href = '/book/guests';
    		}
	    }
	});

	return true;
}

function getBookingError() {

	var error = false;

	//Get the message
	if (Cookies.get('bookingError')) {
		error = window.atob(Cookies.get('bookingError'));
		$('.booking-error').html(error);
		$('.booking-error').show();
	}

	return error;
}


function unsetBookingError(error) {

	//Get the message
	Cookies.remove('bookingError');
}

function setBookingError(error) {
console.log(window.btoa(error));
	//Get the message
	Cookies.set('bookingError', window.btoa(error));
}


function resetBooking() {
	Cookies.remove('bookingDetails');
	if (!$(".steps li[data-url='guests']").hasClass('current-step')) {
		window.location.href = '/book/guests';
	}
}

function returnMomentFromCookie(cookieDate) {
	cookieDate = cookieDate.replace(/ *\([^)]*\) */g, "");
	cookieDate = cookieDate.replace("GMT", "");
	var cookieFormat = "ddd MMM DD YYYY HH:mm:ss ZZ";
	return moment(cookieDate, cookieFormat);
}



async function makeGetRequest(url) {
	return promise = new Promise(function (resolve, reject) {

	  var request = new XMLHttpRequest();
	  request.open('GET', url, true);
	  request.onreadystatechange = function () {
		var f;
		if (request.readyState === 4) {
		  var r = JSON.parse(request.responseText);
		  if (request.status === 200) {
			resolve(r.data);
		  } else {
			reject("failed");
			errorMessages.push("makeGetRequest: " + request.status);
		  }
		}
	  }
	  request.send();
	});
  }



function rememberPreviousDetails() {
console.log("rememberPreviousDetails");
	var errors = "";
console.log(Cookies.get('bookingDetails'));

	if (Cookies.get('bookingDetails')) {

		var details = Cookies.get('bookingDetails');
		details = JSON.parse(window.atob(details));
		console.log(details);
		//If there is a basketId, validate the basket and make them start again if its not valid .
		if (details.basketId) {
			validateBasket();
		}
		
		if ($(".steps li.current-step").attr('data-url') !== 'guests' && details.rooms == null ) {
			console.log("Something is wrong with the booking start again");
			//resetBooking();
		}

		var numRoomsBooked = 0;

		//Restore Menu Details
		if (details.rooms) {
			var people = 0;
			var roomtypes = [];
			for(i=0;i<details.rooms.length;i++) {
				people += parseInt(details.rooms[i].adults);
				people += parseInt(details.rooms[i].children);

				if (details.rooms[i].booked != null && details.rooms[i].booked > 0) {
					numRoomsBooked = numRoomsBooked + 1;
				}
				 

				if (details.rooms[i].roomType != null) {
					roomTypeName = details.rooms[i].roomType;
					if (roomtypes[roomTypeName]) {
						roomtypes[roomTypeName] = roomtypes[roomTypeName] + 1;
					}
					else {
						roomtypes[roomTypeName] = 1;
					}
					
				}
			}

			if (people < 10) {
				people = "0" + people;
			}
			$(".steps li[data-url='guests']").find(".detail").text(people + '/' + details.rooms.length);
			$(".steps li[data-url='guests']").addClass('enabled');

			if (Object.keys(roomtypes).length > 0) {
				roomtypesText = numRoomsBooked;

				if (numRoomsBooked > 1) {
					roomtypesText += " Rooms";
				}
				else {
					roomtypesText += " Room";
				}
				/*for(var roomName in roomtypes) {
					
					if (roomtypes.hasOwnProperty(roomName)) {
						
						roomtypesText = roomtypesText + " " + roomtypes[roomName] + " x " + roomName ;
						
					}
				}*/
				
				$(".steps li[data-url='rooms']").find(".detail").html(roomtypesText);
				$(".steps li[data-url='rooms']").addClass('enabled');
			}

		}




		if ($(".steps li[data-url='guests']").hasClass('current-step')) {
			//If the current tab is guests then restore the room information
			if (details.rooms) {
				//Load the room details
				console.log("load the room details");
				var i;
				for(i=0;i<details.rooms.length;i++) {
					var roomCount = i+1;
					if ($('input[name="numberAdults['+roomCount+']"]').length === 0) {
						addRoom(roomCount);
					}
					var adults = "01";
					if (details.rooms[i].adults < 10) {
						adults = "0" + details.rooms[i].adults;
					}
					else {
						adults = details.rooms[i].adults;
					}

					$('input[name="numberAdults['+roomCount+']"]').val(adults);
					$('input[name="numberAdults['+roomCount+']"]').attr('data-number', details.rooms[i].adults);

					if (details.rooms[i].adults <= 1) {
						$('input[name="numberAdults['+roomCount+']"]').prev('.minus').addClass('disabled');
						$('input[name="numberAdults['+roomCount+']"]').next('.plus').removeClass('disabled');
					}
					else if (details.rooms[i].adults >= maxAdults) {
						$('input[name="numberAdults['+roomCount+']"]').prev('.minus').removeClass('disabled');
						$('input[name="numberAdults['+roomCount+']"]').next('.plus').addClass('disabled');
					}

					var children = "01";

					if (details.rooms[i].children < 10) {
						children = "0" + details.rooms[i].children;
					}
					else {
						children = details.rooms[i].children;
					}

					$('input[name="numberChildren['+roomCount+']"]').val(children);
					$('input[name="numberChildren['+roomCount+']"]').attr('data-number', details.rooms[i].children);
					
					if (details.rooms[i].children == 0) {
						$('input[name="numberChildren['+roomCount+']"]').prev('.minus').addClass('disabled');
						$('input[name="numberChildren['+roomCount+']"]').next('.plus').removeClass('disabled');
					}
					else if (details.rooms[i].children >= maxChildren) {
						$('input[name="numberChildren['+roomCount+']"]').prev('.minus').removeClass('disabled');
						$('input[name="numberChildren['+roomCount+']"]').next('.plus').addClass('disabled');
					}
				}
			}
		}
		var momentDateFrom	= false;
		var momentDateTo = false;
		//Restore Dates
		if (details.dateFrom && details.dateFrom != "" && details.dateTo != "") {
console.log("date details found");
			var dateFormat = "D MMM";

			momentDateFrom  = returnMomentFromCookie(details.dateFrom);
			momentDateTo = returnMomentFromCookie(details.dateTo);

			if (momentDateFrom.isValid()) {
				dateFrom  = momentDateFrom.format(dateFormat);
			}
			else {
				dateFrom  = "";
			}

			if (momentDateTo.isValid()) {
				dateTo = momentDateTo.format(dateFormat);
			}
			else {
				dateTo = "";
			}


			var dateString = ""
			if (dateFrom === dateTo) {
				dateString = dateFrom;
			}
			else {
				dateString = dateFrom + ' - ' + dateTo;
			}
			$(".steps li[data-url='dates']").find(".detail").text(dateString);
			$(".steps li[data-url='dates']").addClass('enabled');
		}

		if ($(".steps li[data-url='dates']").hasClass('current-step') && details.dateFrom != ""  && details.dateTo != "") {
console.log("dates tab");
			var dateFormat = "ddd D MMMM";
			var dateFromInput = "";
			var dateToInput = "";
			if (momentDateFrom ) {
				if (momentDateFrom.isValid()) {
					dateFromInput = momentDateFrom.format(dateFormat);
				}
			}
			if (momentDateTo) {
				if (momentDateTo.isValid()) {
					dateToInput = momentDateTo.format(dateFormat);
				}
			}

			var yearFormat = "YYYY";

			//Default to this year
			var dateFromYear = (new Date()).getFullYear();
			var dateToYear = (new Date()).getFullYear();

			if (momentDateFrom ) {
				if (momentDateFrom.isValid()) {
					dateFromYear =  momentDateFrom.format(yearFormat);
				}
				
			}

			if (momentDateTo) {
				if (momentDateTo.isValid()) {
					dateToYear = momentDateTo.format(yearFormat);
				}
				
			}

			$('#dateFrom').val(dateFromInput);
			$('#dateFromYear').val(dateFromYear);
			$('#dateTo').val(dateToInput);
			$('#dateToYear').val(dateToYear);

			//assumed availabilityupon return
			$('a.button.dates-continue').show();
			//processAvailability
			var details = getDateDetails();
			processRoomTypeAvailability(details,"reload");
		}


		if ($(".steps li[data-url='rooms']").hasClass('current-step')) {
			if (details.rooms.length > 0) {

				//Load the room details
				var i;
				for(i=0;i<details.rooms.length;i++) {

					if (details.rooms[i].booked != false) {
						var roomTab = details.rooms[i].roomNumber + 1;
						//They have already booked a room so mark the button as booked
						var button = $('.room-'+roomTab+' .room-info[data-roomtypeid='+details.rooms[i].roomTypeId+']').find('.row[data-rateid='+details.rooms[i].rateId+'] .add-room-to-basket');
	
						button.addClass('booked').attr('disabled', 'disabled');
		    			button.text('Added');
					}
				}

				if (numRoomsBooked < details.rooms.length && numRoomsBooked > 1) {
					//We haven't choosen a room/rate for all rooms yet
					errors = errors + "You must choose a rate for each room";
				}
				
			}
			else {
				console.log("reset booking");
				resetBooking();
			}
		}

		if ($(".steps li[data-url='extras']").hasClass('current-step')) {
			validateBasket();
			if (details.ArrivalTime != null && details.ArrivalTime.length > 0) {
				$('select[name="arrival_time"]').val(details.ArrivalTime);
				$('select[name="reason"]').val(details.TravelReason);
				$('select[name="how-did-you-hear"]').val(details.CommChannel);
				$('textarea[name="additional_requests"]').val(details.AdditionalRequests);
			}
		}

		if (details.ArrivalTime != null && details.ArrivalTime.length > 0) {
			$(".steps li[data-url='extras']").find(".detail").text(details.ArrivalTime + " Arrival");
			$(".steps li[data-url='extras']").addClass('enabled');
		}
	}

	

	if (errors != "") {
		setBookingError(errors);
	}
	else {
		unsetBookingError();
	}

	getBookingError();
}

function addWarning(title, text, hideContinue) {
	removeWarnings()
	if ($('.warning strong').length > 0) {
		$('.warning').append('<br /><br />');
	}
	$('.warning').append('<strong>'+title+'</strong><span>'+text+'</span>');
	$('.warning').show();
	if (hideContinue) {
		$('.continue').hide()
	}
	
}

function removeWarnings() {
	$('.warning').hide();
	$('.warning').html("");
	$('.continue').show();
}


function addRoom(roomCount) {
	removeWarnings();
	var addedRoom = `
		<div class="field-group field-group-numerical field-group-room ">

			<div class="group-heading">
				<a class="remove-group" href="#remove-group">&times;</a>
				<h3>Room ` + roomCount +` </h3>
			</div>

			<label class="numerical">
				
				<span>Adults</span>
				<a class='minus spinner'></a>
					<input value="02" data-number="2" type="text" name="numberAdults[`+roomCount+`]" class="adults" readonly>
				<a class='plus spinner'></a>

			</label>

			<label class="numerical">
				
				<span>Children</span>
				<a class='minus spinner disabled'></a>
					<input value="00" type="text" data-number="0" name="numberChildren[`+roomCount+`]" class="children" readonly>
				<a class='plus spinner'></a>

			</label>
			
		</div>`;


	$('.room-amount').append(addedRoom);
}

function checkAvailability(data){

	$.ajax({
		type: "post",
		url: " ",
		data: data,
		success: function (response) {
console.log(response);
			if (response == 'true') {
				return response;
				//checkButton.text('Check Availability');
				//checkButton.removeClass('loading');
				//$("#bar-date").daterangepicker("open");
				//$('.booking-bar-unavailable.warning').remove();
				//$('<p class="booking-bar-unavailable warning">These dates are unavailable - please select alternative dates</p>').appendTo('.comiseo-daterangepicker-main');

			}
			else {
				return response;
				//var details = getBookingFormRoomDetails();
				//console.log(details);
				//$(this).addClass("rooms-available");
				//updateBookingCookie(details);
				//window.location = '/book/rooms';
			}
			
		},
		error: function (XMLHttpRequest, textStatus) {
			   console.log(textStatus);
			   //window.location = '/book/rooms';
		}


	});


}

$(window).on("load", function () {

	if (!$('body').hasClass('booking')) {
		Cookies.remove('bookingDetails');

	}

	savedRooms = getSavedRooms();
	$.each(savedRooms, function(index){
		if(this.roomType != undefined ){
			var roomTypeId = this.roomTypeId;
			var roomContent = this.roomType + ' $'+ this.roomRate;
			var requestedRoomCount = 0;
			$('.requested-room-name').each(function(i){
				if( requestedRoomCount == index ){
					var icon = '<span class="icon-check"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 191.667 191.667" style="enable-background:new 0 0 191.667 191.667;" xml:space="preserve"><path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/></svg></span>';
					$(this).html(icon + roomContent).parent('.tab-wrap').parent('a').addClass('has-room');
					$(this).attr('data-roomtypeid', )
				}
				requestedRoomCount ++;	
			});
		}
	});

	//Load all the information about the booking we already have in the cookie.
	if ($('.booking-steps').length > 0) {
		rememberPreviousDetails();
	}
	

	// Open the form
	$( '.booking-toggle, a.booking-toggle , .full-menu button.booking, .room-detail-link').click(function(e) {

		Cookies.set('prevUrl', window.location.pathname);
		e.preventDefault();
		// Prevent use of booking button
		// showBookingNotAvailable(e);
		// return false;
		$(this).find("span").addClass('loading');
		$(this).find("span").text('Loading...');
		$(this).find("div").addClass('loading');
		$(this).find("div").text('Loading...');
		//$(this).addClass('disabled');
		
		var button = $('.booking-toggle');
		var elem = $('.booking-form');
		var width = $( window ).width();

		/*if (width > 1279) {
			openBookingForm(elem, button);
		}
		else {
			window.location = $(this).attr('href');
		}*/

		//Move directly to dates page
		window.location = $(this).attr('href');

	});

	// Close booking button back to previous url
	$('.prev-url').click(function(e){
		e.preventDefault();
		var prevUrl = Cookies.get('prevUrl');
		window.location = prevUrl;
	});
 
	// Close the form

	$('.booking-form span.close').click(function(event){
		var button = $('.booking-toggle');
		var elem = $('.booking-form') ;
		closeBookingForm(elem , button);
		event.preventDefault();
		return false; 
	});

	// Add 'Spinners' to the input labels that require numbers.

	// function addSpinners() {
		$('.booking-form label.numerical')
			.append("<a class='plus spinner'></a>")
			.prepend("<a class='minus spinner'></a>")
	// }

	$('.booking-form label.numerical').each(function () {
		if ($(this).find('input').attr('data-number') <= 1) {
			$(this).find('input').prev().prev('.minus').addClass('disabled');
		}

		if ($(this).find('input').attr('id') == 'adults' && $(this).find('input').val() >= maxAdults) {
			$(this).find('input').next('.plus').addClass('disabled');
		}
	});

	// Increment Numbers with spinner buttons
	
	$(document).on('click', '.numerical > .spinner' , function(e){
		e.preventDefault();
		var $spinner = $(this);
		var $input = $(this).parent().find("input[type='text']");
		var oldValue = $input.val();

		if ($spinner.hasClass('disabled')) {
			//We have hit a limit of some kind so don't allow this to + or -
			return false;
		}

		if ($spinner.hasClass('plus')) {
				var newVal = parseFloat(oldValue) + 1;
		} else {
			// Don't allow decrementing below zero
			if (oldValue > 0) {
					var newVal = parseFloat(oldValue) - 1;
			} else {
					newVal = 0;
			}

			if (newVal == 0) {
				$(this).addClass('disabled');
			}

		}

		$input.attr('data-number', newVal);

		if (newVal < 10) {
			newVal = '0' + newVal;
		}

	  	$input.val(newVal);
	  	updateBookingNav();
	  	return false;
	});


	// Process Icons

	$(document).on('click', '.icon-process' , function(e){

		var src = ($(this).attr('src') === '../assets/images/icon-info-close.svg')
			? '../assets/images/icon-info.svg'
			: '../assets/images/icon-info-close.svg';
		
		$(this).attr('src', src);

		$(this).siblings('.policies').fadeToggle(); 
	});

	// Customiser

	// Additions fake buttons
	$(document).on('click', '.additions .add .fakebutton' , function(e){

		if ($(this).next().hasClass('overlay')) {

			$(this).fadeToggle();
			$(this)
				.next('.overlay')
				.css("display", "flex")
				.hide()
				.fadeToggle();
		}
		

	});

	// Closse the additions mini modal.
	$(document).on('click', '.additions .add .close' , function(e){
		$(this)
			.closest('.overlay')
			// .css("display", "flex")
			.hide()
			// .fadeToggle();
		;

		$(this).closest('.item').find('.fakebutton').fadeIn();
		e.preventDefault();
		return false ;
		
	});

	// Close the additions mini modal when selected.
	$(document).on('click', '.additions .add .overlay a' , function(e){
		$(this)
			.closest('.overlay')
			// .css("display", "flex")
			.hide()
			// .fadeToggle();
		;

		$(this).closest('.item').find('.fakebutton').fadeIn();
		e.preventDefault();
		return false ;
	})

 	// Country Picker 

 	loadCountrySelectFields();

	// Calendar
	$.datepicker.setDefaults({
		 dateFormat: 'D dd MM yy'
	});



	var bookingDetails = [];

	function beginBooking(){


		startCalendar("");

		var button = $('.booking-toggle');
		var elem = $('.booking-form');

		closeBookingForm(elem , button);

		$(".booking-toggle").toggle();

		$('header .branding').removeClass('trans');

	};


	function mobileError(element) {

		if ($(window).width() < 900) {
			$('.warning').appendTo(element.parent().parent().find('.group-heading')).addClass('mobile-warning');
		}
	}
	//Rules about Occupancy
	$(document).on('click', '.field-group-room .plus', function (e) {
		e.preventDefault();
		//If we are adding always allow remove again
		$(this).prev().prev('.minus').removeClass('disabled');

		if ($(this).prev('input').hasClass('adults') && $(this).prev('input').attr('data-number') >= maxAdults && $(this).hasClass('disabled')) {
			addWarning('Maximum number of adults reached', 'There is a maximum of ' + maxAdults + ' adults per room');
			mobileError($(this));
		}

		if ($(this).prev('input').hasClass('adults') && $(this).prev('input').attr('data-number') >= minAdults) {
			$(this).removeClass('disabled');
		}
		if ($(this).prev('input').hasClass('adults') && $(this).prev('input').attr('data-number') >= maxAdults) {
			
			$(this).addClass('disabled');
			$(this).prev().prev('.minus').removeClass('disabled');
		}
		if ($(this).prev('input').hasClass('children') && $(this).prev('input').attr('data-number') >= maxChildren && $(this).hasClass('disabled')) {

			addWarning('Maximum number of children reached', 'There is a maximum of ' + maxChildren + ' children per room');
			mobileError($(this));
		}
		if ($(this).prev('input').hasClass('children') && $(this).prev('input').attr('data-number') >= maxChildren) {
			$(this).addClass('disabled');
			$(this).prev().prev('.minus').removeClass('disabled');
		}
	});

	$(document).on('click', '.field-group-room .minus', function (e) {
		e.preventDefault();

		if ($(this).next('input').hasClass('adults') && $(this).next('input').attr('data-number') == minAdults && $(this).hasClass('disabled')) {
			//Its the second time they have clicked.
			addWarning('Minimum number of adults reached', 'There must be at least '+minAdults+' adult per room');
			mobileError($(this));
		}
		if ($(this).next('input').hasClass('adults') && $(this).next('input').attr('data-number') == minAdults) {
			$(this).addClass('disabled');
			$(this).next().next('.plus').removeClass('disabled');
			
		}

		if ($(this).next('input').hasClass('adults') && $(this).next('input').attr('data-number') < maxAdults) {
			$(this).next().next('.plus').removeClass('disabled');
		}
		if ($(this).next('input').hasClass('children') && $(this).next('input').attr('data-number') < maxChildren) {
			$(this).next().next('.plus').removeClass('disabled');
		}
	});

	//Rules about occupancy on booking bar
	$(document).on('click', '.booking-bar-group .plus', function (e) {

		var numRooms = $('#rooms').attr('data-number');
		var numberAdults = $('#adults').attr('data-number');

		$(this).prev().prev().prev('.minus').addClass('disabled');

		if ($(this).prev('input').hasClass('rooms')) {

			removeWarnings();

			if (numRooms >= totalRoomCount) {
				$(this).addClass('disabled');
			}

			if (numberAdults == numRooms) {

				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');

				$('#adults').prev().prev().prev('.minus').addClass('disabled');
				$('#adults').prev().prev().prev('.minus').addClass('disabled');
				var minAdultsValue = (minAdults * numRooms);
				if (minAdultsValue < 10) {
					minAdultsValue = '0' + minAdultsValue;
				}
				$('#adults').val(minAdultsValue);
				$('#adults').attr('data-number', (minAdults * numRooms));

			}

			if (numberAdults < numRooms) {
				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');

				$('#adults').prev().prev().prev('.minus').addClass('disabled');
				var minAdultsValue = (minAdults * numRooms);
				if (minAdultsValue < 10) {
					minAdultsValue = '0' + minAdultsValue;
				}
				$('#adults').val(minAdultsValue);
				$('#adults').attr('data-number', (minAdults * numRooms));

			}
			
			$('#adults').next('.plus').removeClass('disabled');
			$('#children').next('.plus').removeClass('disabled');
			if (numRooms > 1) {
				$(this).prev().prev().prev('.minus').removeClass('disabled');
			}
		}


		if ($(this).prev('input').hasClass('adults')) {

			var averageAdults = numberAdults / numRooms;


			if ( averageAdults >= maxAdults && $(this).hasClass('disabled')) {

				if (numRooms < totalRoomCount) {
					$(this).removeClass('disabled');
					var currentAdultNumber = $('#adults').attr('data-number');
					var currentAdultVal = $('#adults').val();
					currentAdultNumber = parseInt(currentAdultNumber) + 1;
					if (currentAdultNumber < 10) {
						currentAdultVal = '0' + currentAdultNumber;
					}
					$('#adults').val(currentAdultVal);
					$('#adults').attr('data-number', currentAdultNumber);
					var currentRoomNumber = $('#rooms').attr('data-number');
					var currentRoomVal = $('#rooms').val();
					currentRoomNumber = parseInt(currentRoomNumber) + 1;
					if (currentRoomNumber < 10) {
						currentRoomVal = '0' + currentRoomNumber;
					}
					$('#rooms').val(currentRoomVal);
					$('#rooms').attr('data-number', currentRoomNumber);
					
					averageAdults = currentAdultNumber / currentRoomNumber;
				}
				
			}

			if ( $(this).prev('input').attr('data-number') >= minAdults) {
				$(this).removeClass('disabled');
			}
			if ( averageAdults >= maxAdults ) {

				$(this).addClass('disabled');
				$(this).prev().prev().prev('.minus').removeClass('disabled');
			}
			else {
				$(this).prev().prev().prev('.minus').removeClass('disabled');
				removeWarnings();
			}

			
		}

		if ($(this).prev('input').hasClass('children')) {
			var numberChildren = $(this).prev('input').attr('data-number');
			var averageChildren = numberChildren / numRooms;

			if ( averageChildren >= maxChildren) {

				

				$(this).addClass('disabled');
				$(this).prev().prev().prev('.minus').removeClass('disabled');
			}
			else {
				$(this).removeClass('disabled');
				removeWarnings();
			}
		}

	});


	$(document).on('click', '.booking-bar-group .minus', function (e) {

		var numRooms = $('#rooms').attr('data-number');
		var numberAdults = $('#adults').attr('data-number');
		var numberChildren = $('#children').attr('data-number');

		$(this).next().next().next('.plus').removeClass('disabled');

		if ($('#children').attr('data-number') == 0) {
			$('#children').prev().prev('.minus').addClass('disabled');
		}

		if ($(this).next().next('input').hasClass('rooms')) {

			if (numberAdults == numRooms) {
				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');
				$('#adults').next('.plus').addClass('disabled');
			}

			if (numberAdults > numRooms) {
				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');

				var minAdultsValue = (minAdults * numRooms);
				if (minAdultsValue < 10) {
					minAdultsValue = '0' + minAdultsValue;
				}
				$('#adults').val(minAdultsValue);
				$('#adults').attr('data-number', (minAdults * numRooms))
			}

			if (numRooms == 1) {
				$(this).addClass('disabled');
			}

			//If we are decrementing rooms then we need to handle max adults and max children

			//if numberAdults is now greater than allowed, resuce to max allowed and show warning.
			if (numberAdults > (maxAdults * numRooms)) {
				var maxAdultsValue = (maxAdults * numRooms);
				if (maxAdultsValue < 10) {
					maxAdultsValue = '0' + maxAdultsValue;
				}
				$('#adults').val(maxAdultsValue);
				$('#adults').attr('data-number', (maxAdults * numRooms))
			}

			if (numberChildren > (maxChildren * numRooms)) {
				var maxChildrenValue = (maxChildren * numRooms);
				if (maxChildrenValue < 10) {
					maxChildrenValue = '0' + maxChildrenValue;
				}
				$('#children').val(maxChildrenValue);
				$('#children').attr('data-number', (maxChildren * numRooms))
			}
			

		}


		if ($(this).next().next('input').hasClass('adults')) {


			if ($(this).next().next('input').attr('data-number') <= minAdults) {
				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');
				$(this).addClass('disabled');
				var minAdultsValue = (minAdults * numRooms);
				if (minAdultsValue < 10) {
					minAdultsValue = '0' + minAdultsValue;
				}
				$('#adults').val(minAdultsValue);
				$('#adults').attr('data-number', (minAdults * numRooms));
				$(this).next().next().next('.plus').removeClass('disabled');
			}

			if ($(this).next().next('input').attr('data-number') <= (minAdults * numRooms)) {
				//addWarning('Minimum number of adults reached', '<br />There must be at least '+minAdults+' adult per room');
				$(this).addClass('disabled');
				var minAdultsValue = (minAdults * numRooms);
				if (minAdultsValue < 10) {
					minAdultsValue = '0' + minAdultsValue;
				}
				$('#adults').val(minAdultsValue);
				$('#adults').attr('data-number', (minAdults * numRooms));
				$(this).next().next().next('.plus').removeClass('disabled');
			}
			
		}

	});


	// Add Room
	$(document).on('click', 'a.button.add' , function(e){

		// Count Rooms
		var roomCount = $('.field-group-room').length + 1;

		//Rules for max rooms
		if ($('.room-amount .field-group-room').length >= totalRoomCount) {
			addWarning('Maximum number of rooms reached', 'Sorry you have reached the maximum number of rooms that can be booked online.');
			$(this).hide();
			return;
		}

		addRoom(roomCount);
		
		updateBookingNav();
	});


	// Remove field group from booking process


	$(document).on('click', '.field-group a.remove-group' , function(e){

		$(this).closest('.field-group').remove();

		if ($('.room-amount .field-group-room').length <= totalRoomCount ) {
			removeWarnings();
			$('a.button.add').show();
		}
		updateBookingNav();
	});


	// Run the actual calendar on load 

	if ($('#datepicker').length > 0) {
		startCalendar('#datepicker');
	}

	if ($('#bar-date').length > 0 && !$('body').hasClass('modal-open')) {
		startBookingBar();
	}
	


	// Code Modal

	$(".field-group.code button").click(function(){
		$('.field-group.code label').toggleClass('open-modal');
	});

	// Booking Tabs

	$(document).on('click', 'ul.steps li:not(.details)' , function(e){
		
		var valid = nextStep($('.booking-steps .steps li.current-step').attr('data-url'));
		e.preventDefault();
		if (valid) {
			window.location = $(this).find('a').attr('href');
		}
		
	});

	function nextStep(currentStep) {
console.log(currentStep);		
		var details = "";
		if (currentStep == "guests") {
			
			if ($('.booking-steps .steps li.current-step').attr('data-url') == 'guests') {
				//If we are currently on the guest step lets save the details before moving.
				details = getRoomDetails();
			}
			
		}
		if (currentStep == "dates") {

			if ($('.booking-steps .steps li.current-step').attr('data-url') == 'dates') {
				//If we are currently on the dates step lets save the details before moving.
				details = getDateDetails();
			}

		}
console.log(details);
		updateBookingCookie(details, currentStep);
		return true;
	}

	$(document).on('click', 'ul.steps li.details' , function(e){
		$(this).toggleClass('flip');
		$('.booking-steps').toggleClass('open');
	});

	$(document).on('click', '.tab-content a.button.continue' , function(e){
console.log("continue clicked");
	e.preventDefault();
		if ($(this).hasClass('complete-booking')){
			completeBooking(e);
			return false;
		}
		
		$(this).find('div').addClass('loading');
		$(this).find('div').text('Loading...');
		//$(this).find('div').addClass('disabled');
		nextStep($('.booking-steps .steps li.current-step').attr('data-url'));
		/*if ($(this).hasClass('dates-continue')) {
			window.location = $(this).find('a').attr('href');
		}*/
		
console.log($(this).attr('href'));		
		window.location = $(this).attr('href');
		

	});


	// Tabs for Selected Room
	var room = window.location.hash.substr(1);
console.log(room);

	if (room != "") {
		var selected_room = room;
		$('.select-room > a').removeClass('selected selected-room-choice');
		$('.select-room > a[data-room="'+room+'"]').addClass('selected selected-room-choice');

		$('.room-select-container > div ').removeClass('selected');

		$(".room-"+selected_room).addClass('selected');
	}

	$('.select-room > a ').click(function(){
		var selected_room = $(this).attr('data-room');

		$('.select-room > a').removeClass('selected selected-room-choice');
		$(this).addClass('selected selected-room-choice');

		$('.room-select-container > div ').removeClass('selected');

		$(".room-"+selected_room).addClass('selected');
	})


	// Collapse to show / hide the table

	$(document).on('click', '.rates > button.toggle-calendar' , function(e){
		$(this).closest('.room-row').find('.availability-info.table').toggle();
		var thisRoomTypeID = $(this).closest('.room-row').find('.availability-info.table').attr('data-roomtypeid');

		$(this).text(function(i, text){
			return text === "Search dates" ? "Hide Search" : "Search dates";
		})
		var defaultDate = new Date($("#wbe_from").attr('data-datevalue'));

		/*if ($("#wbe_from").length != 0 && $("#wbe_from").data('datevalue') !== "") {
			defaultDate = $.datepicker.parseDate("D dd MM yy", $("#wbe_from").data('datevalue') );
			console.log(defaultDate);
		}*/
		$(this).toggleClass('active');

		var $selector = $(this).closest('.room-row').find('.availability-info.table').find('div.calendar div');
		console.log($selector.siblings(".wbe_to").attr('data-datevalue'));
		if ($selector.children('div.ui-datepicker').length == 0){
			$selector.datepicker({

				// dateFormat: "dd-mm" ,
				dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
				minDate: 0,
				numberOfMonths: [2,1],
				defaultDate:defaultDate,
				beforeShowDay: function(date) {
					var dateFormat = "D dd MM yy";
					//var dateFrom = "";
					//var dateTo = "";
					var dateFrom =  new Date($selector.siblings(".wbe_from").attr('data-datevalue'));

					var dateTo = new Date($selector.siblings(".wbe_to").attr('data-datevalue'));

					/*if ($("#dateFrom").val() != "") {
					
						dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
					}
					if ($("#dateTo").val() != "") {
						dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
					}*/
		
					var numNights = "";
		
					if (dateFrom && dateTo) {
						//Can only work out number of nights if we have both a to and from date
						dateFromTime = dateFrom.getTime();
						dateToTime = dateTo.getTime();
		
						var dateDiff =  dateToTime - dateFromTime;
						var numDays = (dateDiff/(1000*60*60*24));
		
						if (numDays > 0) {
							numNights = numDays;
						}
					}
		
					//TO DO add word depart to last date
		
					//$('#nights').val(numNights);
					var hightlightClass="";
					highlightClass = dateFrom && ((date.getTime() == dateFrom.getTime()) || (dateTo && date >= dateFrom && date <= dateTo)) ? "dp-highlight" : "";

					if (dateTo) {
						if (highlightClass === "dp-highlight" && dateTo.getTime() === date.getTime()) {
							//Add the depart class for the last day
							highlightClass = highlightClass + " depart";
						}
					}
		
					if (dateFrom) {
						if (highlightClass === "dp-highlight" && dateFrom.getTime() === date.getTime()) {
							//Add the depart class for the last day
							highlightClass = highlightClass + " arrive";
						}
					}
		
					return [true, highlightClass];
				},
				onSelect: function(dateText, inst) {

					var selectedYear = inst.selectedYear;
					var dateFormat = "D dd MM yy";
					var dateFrom =  "";
					//dateFrom = new Date($("#wbe_from").attr('data-datevalue'));

					var dateTo = "";
					//dateTo = new Date($("#wbe_to").attr('data-datevalue'));
	
					
					if ($selector.siblings(".wbe_from").attr('data-datevalue')) {
						dateFrom = new Date($selector.siblings(".wbe_from").attr('data-datevalue'));
					}

					if ($selector.siblings(".wbe_to").attr('data-datevalue')) {
						dateTo = new Date($selector.siblings(".wbe_to").attr('data-datevalue'));
					}
					
					/*if ($("#dateFrom").val() != "") {
						dateFrom = $.datepicker.parseDate(dateFormat, $("#dateFrom").val() + " " + $("#dateFromYear").val());
					}
					if ($("#dateTo").val() != "") {
						dateTo = $.datepicker.parseDate(dateFormat, $("#dateTo").val() + " " +$("#dateToYear").val());
					}*/
					//var date1 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $("#dateFrom").val());
					//var date2 = $.datepicker.parseDate($.datepicker._defaults.dateFormat, $("#dateTo").val());
					var selectedDate = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
	
					if (selectedDate && dateTo ) {

						//$("#wbe_from").val(dateText);
						$selector.siblings(".wbe_from").attr('data-datevalue',selectedDate);
						//$("#dateFromYear").val(selectedYear);
						$(inst).addClass('arrive');
						$selector.siblings(".wbe_to").removeAttr('data-datevalue');
						$(this).datepicker();
		
					} else if( selectedDate && dateFrom && !dateTo ) {
			
						$selector.siblings(".wbe_to").attr('data-datevalue',selectedDate);
						//$("#dateToYear").val($("#dateFromYear").val());
						//$("#wbe_from").val( dateText ).attr('data-datevalue',selectedDate);
						//$("#dateFromYear").val(selectedYear);
		
						$(this).datepicker();
		
					}


					
					//updateBookingNav();
					//var details = getDateDetails();
					//updateBookingCookie(details, 'dates');
		
				}
			});
		}
		
		var $button = $selector.parent('.calendar').siblings('.banner-button').children('button');
		console.log($button);
		$button.on('click',function(e){
			var init_apiKey = "ebc5c39f-3fb4-47d1-b142-523f8881fee2";
			var init_apiUrl = "https://sydney.protel.net";
			var protelURL = init_apiUrl + "/WBE/1/";
			//console.log($selector);
			var selectCount = $selector.find('td.dp-highlight').length;
			//console.log(selectCount);
			var selector  = 'td.dp-highlight[data-handler="selectDay"]';
			$('div.availability-legend').html('<p style="">Checking availabilities...</span></p></div>');
			if (selectCount != 0){
				//$('div.availability-legend').remove();
				//$('<div class="availability-legend"><p>Checking availabilities...</p></div>').appendTo('.comiseo-daterangepicker');
				var $firstDay = $selector.find("td.dp-highlight[data-handler='selectDay']:first");
				//console.log($firstDay);
				var first = {};
				first.day = $firstDay.find("a").html();
				first.month = parseInt($firstDay.attr("data-month"));
				first.year = $firstDay.attr("data-year");
				var $lastDay = $selector.find("td.dp-highlight[data-handler='selectDay']:last");		
				var last = {};
				last.day = $lastDay.find("a").html();
				last.month = parseInt($lastDay.attr("data-month"));
				last.year = $lastDay.attr("data-year"); 
				var firstDate = moment([first.year,first.month,first.day]);
				var lastDate = moment([last.year,last.month,last.day]);

				var dateDiff = lastDate.diff(firstDate,'days');
				//console.log(dateDiff);
				$selector.find('td.dp-highlight[data-handler="selectDay"]').each(async function(i){

					$(this).removeClass('available').removeClass('not-available').removeClass('low-availability');
					var date = {};
					var availableRoomTypes = [];
					$(this).find("a").on("click", function(e) {
						e.preventDefault();
					});;
					date.day = $(this).find("a").html();
					date.month = parseInt($(this).attr("data-month"));
					date.year = $(this).attr("data-year");
					var today = moment([date.year,date.month,date.day]);
					var tomorrow = moment([date.year,date.month,date.day]).add(1,'days');

					var attributes = [];

					attributes.dateFrom = today.format("YYYY-MM-DD");
					attributes.dateTo = tomorrow.format("YYYY-MM-DD");
//console.log(thisRoomTypeID);
					var selectedRoomTypes = [thisRoomTypeID];
					//var ar = await getUnavailableDates(attributes.dateFrom,attributes.dateTo,$(this));
					var rooms = await makeGetRequest(protelURL + init_apiKey + "/rates?format=json&isocode=en&type=standardroom&from=" + attributes.dateFrom + "&to=" + attributes.dateTo + "&countrooms=1");
//console.log(rooms);

					var roomCount = 0;
					var filteredRoomTypes;
					rooms.instances.forEach(function(i){
						//console.log(i);
						if (i.roomTypes) {
							filteredRoomTypes = i.roomTypes.filter(f => f.id == thisRoomTypeID);
							
						}
					});
					if (filteredRoomTypes && filteredRoomTypes.length > 0){
						//console.log(filteredRoomTypes);
						if (filteredRoomTypes[0].freeRoomsCount == 1) {
							$(this).addClass("available low-availability");
						} else {
							//console.log(filteredRoomTypes[0].freeRoomsCount);
							$(this).addClass("available");
						}
					} else {
						$(this).addClass("not-available");
					}
					$('div.availability-legend').html('<p>RESULTS</p><div class="results-legend" style=""><span class="legend-row"><span style="display:inline-block;width:23px; height:23px; border:1px solid green;background-color:RGBA(0,255,0,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = Available</span></span><span class="legend-row"><span style="display:inline-block;width:23px; height:23px; border:1px solid orange;background-color:RGBA(235,151,15,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = Limited Availability (Only 1 room available) </span></span><span class="legend-row"><span style="display:inline-block;width:23px; height:23px; border:1px solid red;background-color:RGBA(255,0,0,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = No Availability</span></span></div>');

					//console.log($(this));
				});
			}
		});

	});


	$(document).on('click', '.rates > button.show-hide' , function(e){
		$(this).closest('.room-row').find('.room-info.table').toggle();

		$(this).text(function(i, text){
			return text === "Show Rates" ? "Hide Rates" : "Show Rates";
		})

		$(this).toggleClass('active');
	});

	$(document).on('click', '.rates > button.change' , function(e){
		$(this).text('Loading...');	
		$(this).addClass('loading');
		$(this).prop('disabled', true);
		window.location = $(this).attr('data-value');
	});

	function logoSwitch(path){
		$('header .branding object').attr('data' , path ) ;
		$('header .branding object').removeClass('trans') ;
		$('header .branding').addClass('logo-pulled'); 
		window.setTimeout(function(){ $('header .branding').removeClass('logo-pulled');}, 1200);

	}


	$(document).on('click', '.booking-steps .steps > li' , function(e){

		if ($(window).width() < 768) {
			$(this).siblings().slideToggle();
		}

	});

	function getRoomDetails() {

		var details = {rooms: new Array() };
		var i = 1;

		$('.field-group-room').each(function () {
			details.rooms[i] = {adults: $('input[name="numberAdults['+i+']"]').attr('data-number'), children: $('input[name="numberChildren['+i+']"]').attr('data-number') };
			i++;
		});

		return details;
	}

	function getBookingFormRoomDetails() {

		var details = {rooms: new Array() };
		var i = 1;

		var numRooms = $('#rooms').attr('data-number');
		var numAdults = $('#adults').attr('data-number');
		var numChildren = $('#children').attr('data-number');

		var currentRooms = numRooms;
		var currentAdults = numAdults;
		var currentChildren = numChildren;

		for (i=1;i<=numRooms;i++) {

			var avgAdults = currentAdults / currentRooms;
			var avgChildren = currentChildren / currentRooms;

			if (avgAdults >= 1.5) {
				details.rooms[i] = {adults: Math.ceil(avgAdults)};
				currentAdults = currentAdults - Math.ceil(avgAdults);
			}
			else {
				details.rooms[i] = {adults: Math.floor(avgAdults)};
				currentAdults = currentAdults - Math.floor(avgAdults);
			}

			if (avgChildren >= 0.5) {
				details.rooms[i].children = Math.ceil(avgChildren);
				currentChildren = currentChildren - Math.ceil(avgChildren);
			}
			else {
				details.rooms[i].children = Math.floor(avgChildren);
				currentChildren = currentChildren - Math.floor(avgChildren);
			}

			currentRooms = currentRooms - 1;
			
		}

		return details;
	}




	
	// Cancel out / x button

	$('.date-picker .close').click(function() {
		$('.date-picker').toggleClass('open').toggleClass('closed')
	});

	// Start the booking process

	$('.booking-bar-check').click(function(e) {

		$(this).text('Loading...');
		$(this).addClass('loading');
		

		e.preventDefault();

		var checkButton = $('.booking-bar-check');

		//getUnavailableDates(dateFrom.format("YYYY-MM-DD"), dateTo.format("YYYY-MM-DD"));

		if ($('#dateFrom').val() == "") {
			var details = getBookingFormRoomDetails();
			updateBookingCookie(details);
			window.location = '/book/dates';
		}
		else {
			if ($('#dateTo').val() == "") {
				var tomorrow = moment($('#dateFrom').val() + ' ' + $('#dateFromYear').val(), 'ddd DD MMMM YYYY').add(1, 'd');

				$("#dateTo").val(tomorrow.format('ddd DD MMMM'));
				$("#dateToYear").val(tomorrow.format('YYYY'));
				var details = getDateDetails();
				updateBookingCookie(details, 'dates');
			}

			//Check availability for these dates.
			var dateFrom = moment($('#dateFrom').val() + ' ' + $('#dateFromYear').val(), 'ddd DD MMMM YYYY');
			var dateTo = moment($('#dateTo').val() + ' ' + $('#dateToYear').val(), 'ddd DD MMMM YYYY');
			var attributes = {};

			attributes.dateFrom = dateFrom.format("YYYY-MM-DD");
			attributes.dateTo = dateTo.format("YYYY-MM-DD");

			var data = {
			    action: 'protel/default/quick-dates',
			    attributes: attributes
			};

			data[window.csrfTokenName] = window.csrfTokenValue;

			$.ajax({
				type: "post",
				url: "",
				data: data,
			    success: function (response) {

			    	if (response == 'true') {

		    			checkButton.text('Check Availability');
						checkButton.removeClass('loading');
		    			$("#bar-date").daterangepicker("open");
		    			$('.booking-bar-unavailable.warning').remove();
		    			$('<p class="booking-bar-unavailable warning">These dates are unavailable - please select alternative dates</p>').appendTo('.comiseo-daterangepicker-main');

			    	}
			    	else {
		    			var details = getBookingFormRoomDetails();
						console.log(details);
						updateBookingCookie(details);
						//window.location = '/book/rooms';
		    		}
			    	
			    },
			    error: function (XMLHttpRequest, textStatus) {
				       console.log(textStatus);
				       //window.location = '/book/rooms';
			    }
			});
		}
		
	});

	$('.booking-bar-check-range').click(function(e) {
		var init_apiKey = "ebc5c39f-3fb4-47d1-b142-523f8881fee2";
		var init_apiUrl = "https://sydney.protel.net";
		var protelURL = init_apiUrl + "/WBE/1/";
		var selectedRoomTypes = [];
		$('.room-type-selector.selected').each(function(r){
			console.log($(this).data('roomtypeid'));
			selectedRoomTypes.push($(this).data('roomtypeid'));
		});
console.log(selectedRoomTypes);		
		//var $lastDay = $("div.ui-datepicker-group-last table.ui-datepicker-calendar td[data-handler='selectDay']:last");
		
		e.preventDefault();

		var checkButton = $('.booking-bar-check');
		var count = 0;
		var selectCount = $('td.dp-highlight').length;
		var selector  = 'td.dp-highlight[data-handler="selectDay"]';
		if (selectCount != 0){
			$('div.availability-legend').remove();
			$('<div class="availability-legend"><p>Checking availabilities...</p></div>').appendTo('.comiseo-daterangepicker');
			var $firstDay = $("td.dp-highlight[data-handler='selectDay']:first");
			var first = {};
			first.day = $firstDay.find("a").html();
			first.month = parseInt($firstDay.attr("data-month"));
			first.year = $firstDay.attr("data-year"); 
			var $lastDay = $("td.dp-highlight[data-handler='selectDay']:last");
			
			var last = {};
			last.day = $lastDay.find("a").html();
			last.month = parseInt($lastDay.attr("data-month"));
			last.year = $lastDay.attr("data-year"); 
			var firstDate = moment([first.year,first.month,first.day]);
			var lastDate = moment([last.year,last.month,last.day]);

			var dateDiff = lastDate.diff(firstDate,'days');
			$(this).text('Loading...');
			$(this).addClass('loading');
			$(selector).each(async function(i){
				//console.log($(this).hasClass('depart'));
				$(this).removeClass('available').removeClass('not-available').removeClass('low-availability');
					var date = {};
					var availableRoomTypes = [];
					$(this).find("a").on("click", function(e) {
						e.preventDefault();
					});;
					date.day = $(this).find("a").html();
					date.month = parseInt($(this).attr("data-month"));
					date.year = $(this).attr("data-year");
					var today = moment([date.year,date.month,date.day]);
					var tomorrow = moment([date.year,date.month,date.day]).add(1,'days');

					var attributes = {};

					attributes.dateFrom = today.format("YYYY-MM-DD");
					attributes.dateTo = tomorrow.format("YYYY-MM-DD");

					//var ar = await getUnavailableDates(attributes.dateFrom,attributes.dateTo,$(this));
					var rooms = await makeGetRequest(protelURL + init_apiKey + "/rates?format=json&isocode=en&type=standardroom&from=" + attributes.dateFrom + "&to=" + attributes.dateTo + "&countrooms=1");

					var roomCount = 0;
					
					rooms.instances.forEach(function(i){

						if (i.roomTypes) {
							var filteredRoomTypes = i.roomTypes.filter(f => selectedRoomTypes.indexOf(f.id) > -1 );
					
							if (filteredRoomTypes){
								filteredRoomTypes.forEach(function(r){
												roomCount = roomCount + r.freeRoomsCount;
												console.log(r.freeRoomsCount);
												if (r.freeRoomsCount > 0){
													availableRoomTypes.push(r.id);

												}
								});
							}
						}
						
						});

					if (selectedRoomTypes.length >0) {
						if (availableRoomTypes.length == 0){
							$(this).addClass("not-available");
						} else if (availableRoomTypes.length == selectedRoomTypes.length) {
							$(this).addClass("available ");
						} else {
							$(this).addClass("available low-availability");
						}
					} else {
						$('div.availability-legend').html('<p style="color:red;">Please select at least 1 room type.</span></p></div>');
					}
						
									

					if (date.day == last.day && date.month == last.month && date.year == last.year) {
						$('.booking-bar-check-range').text('Check Availability');
						$('.booking-bar-check-range').removeClass('loading');
						var notAvailableCount = $('.not-available').not('.depart').length;

						if (notAvailableCount == 0) {
							console.log("change button text");
							$('a.button.pre-select').text("Book These Dates");
						} else {
							$('a.button.pre-select').text("Make A Reservation");
						}
			
						//$('div.availability-legend').html('<p>Results</p>');
						$('div.availability-legend').html('<p>RESULTS</p><div style="display:flex;flex-direction:row;align-content:center;justify-content:center;"><span style="display:inline-block;width:23px; height:23px; border:1px solid green;background-color:RGBA(0,255,0,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = Available</span><span style="display:inline-block;width:23px; height:23px; border:1px solid orange;background-color:RGBA(235,151,15,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = Limited Availability (Not all of the selected room types available) </span><span style="display:inline-block;width:23px; height:23px; border:1px solid red;background-color:RGBA(255,0,0,0.1)"></span><span style="line-height:23px;vertical-align:middle;margin-left:8px;margin-right:8px;"> = No Availability</span></div>');
					}
				
			});
			
			} else {
				$('div.availability-legend').html('<p style="color:red;">Please select a date range before clicking <span style="font-family:AvenirNextLTPro-Regular, sans-serif">CHECK AVAILABILITY</span></p></div>');
			}
	});

	$(document).on('click', '.add-room-to-basket' , function (e) {
		
		$(this).text('Loading...');	
		$(this).addClass('loading');
		$(this).prop('disabled', true);

		var button = $(this);
		var roomNumber = $('.select-room .selected').attr('data-room') - 1;


		// TO DO
		//First we need to check if this room already has a room booked. If it does, delete the existing one and add the new one.
		rooms = getSavedRooms();
console.log(rooms);		
		totalRooms = rooms.length;
		var currentRoom = rooms[roomNumber];

		var attributes = currentRoom;

		attributes.roomTypeId = button.closest('.room-info.table').attr('data-roomtypeid');
		attributes.rateId = button.closest('.row').attr('data-rateid');
		attributes.roomRate = button.closest('.row').find('.room-rate').val();		
		attributes.roomNumber = roomNumber;
		attributes.booked = currentRoom.booked;
console.log(currentRoom.booked);
		if (attributes.rateId == null) {
			attributes.rateId = button.parent().attr('data-rateid');
		} 

		var data = {
		    action: 'protel/default/book-room',
		    attributes: attributes
		};

		data[window.csrfTokenName] = window.csrfTokenValue;

		//Book Room button
		$.ajax({
			type: "post",
			url: "",
			data: data,
		    success: function (response) {
		    	if (response !== false && response !== "false") {
		    		response = JSON.parse(response);
		    		var roomType = button.closest('.room-info.table').attr('data-roomtype');
		    		var roomTypeId = button.closest('.room-info.table').attr('data-roomtypeid');
		    		currentRoom.roomType = roomType

		    		currentRoom.booked = response.roomBookingId;
		    		rooms[roomNumber] = currentRoom;
		    		details = {basketId: response.basketId, rooms: rooms };
		    		edited = true;
		    		updateBookingCookie(details, 'rooms');
		    		var roomTab = roomNumber + 1;
		    		button.closest('.room-choice.room-' + roomTab).find('.add-room-to-basket').removeClass('booked').text('Add To Booking').removeAttr('disabled');
		    		button.addClass('booked').attr('disabled', 'disabled');
		    		button.text('Added');
		    		button.removeClass('loading');
		    		//Here decrement the freeRoomCount for both the room and the rate on all tabs
		    		var roomCount = button.closest('.room-row').attr('data-free-rooms');
		    		roomCount = roomCount - 1;
		    		var roomCountId = button.closest('.room-row').attr('data-free-room-id');
		    		$('.room-row[data-free-room-id='+roomCountId+']').attr('data-free-rooms', roomCount);

		    		if (roomCount < 1) {
		    			$('.room-row[data-free-room-id='+roomCountId+']').addClass('unavailable').removeClass('limited');
		    			$('.room-row[data-free-room-id='+roomCountId+'] button.show-hide').attr('disabled', 'disabled').text('Added');
						$('.room-row[data-free-room-id='+roomCountId+'] .na-message').text('No more rooms available for these dates');
						$('.room-row[data-free-room-id='+roomCountId+']').find('a.button').show();
						$('.room-row[data-free-room-id='+roomCountId+'] .rates > span').hide();
						$('.room-row[data-free-room-id='+roomCountId+'] .rates > strong').hide();
		    		}	

		    		/*$('.booking-error .success').html("<p><strong>Room " + roomTab + " successfully selected. </strong></p><br />");*/
		    		var i;
		    		var selectedRoomTypes = [];

		    		//Here we hide room types that have already been used.
		    		for (i=0;i<rooms.length;i++) {
		    			var selectedRoomNumber = i+1;
		    			if (rooms[i].roomTypeId != undefined) {
		    				selectedRoomTypes[selectedRoomNumber] = rooms[i].roomTypeId;
			    		}
		    		}

		    		$('.room-row').each(function () {

		    			//Current tab we are on
		    			var roomClass = $(this).parent('.room-choice').attr('class');

		    			var roomRegex = /room-([0-9])/gm;
		    			var match = roomRegex.exec(roomClass);
		    			var currentRoom = match[1];

		    			$(this).removeClass('already-selected');

		    			var updatedRoomNumber = selectedRoomTypes.indexOf($(this).attr('data-free-room-id'));

			    		if (updatedRoomNumber >= 0) {
			    			if (currentRoom != updatedRoomNumber) {
			    				$(this).addClass('already-selected');
			    			}
			    			
			    		}
		    		});

		    		if (roomTab < totalRooms) {
		    			var nextRoom = roomTab + 1;

		    			//Move to the next room
		    			setTimeout(function () {
						   $('a[data-room="'+nextRoom+'"').delay('3000').trigger('click');
						   $("html, body").animate({ scrollTop: 0 }, "slow");
						   	//Here add a progress message
						}, 1000);
		    			
		    		}
		    		else if (roomTab == totalRooms) {
		    			window.location = "/book/extras";
		    		}


		    	}
		    	else {
		    		button.after('<p class="booking-error">Failed to add to basket</p>')
		    	}

		        var roomName = button.closest('.room-info.table').attr('data-roomtype');
		        var icon = '<span class="icon-check"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 191.667 191.667" style="enable-background:new 0 0 191.667 191.667;" xml:space="preserve"><path d="M95.833,0C42.991,0,0,42.99,0,95.833s42.991,95.834,95.833,95.834s95.833-42.991,95.833-95.834S148.676,0,95.833,0z M150.862,79.646l-60.207,60.207c-2.56,2.56-5.963,3.969-9.583,3.969c-3.62,0-7.023-1.409-9.583-3.969l-30.685-30.685 c-2.56-2.56-3.97-5.963-3.97-9.583c0-3.621,1.41-7.024,3.97-9.584c2.559-2.56,5.962-3.97,9.583-3.97c3.62,0,7.024,1.41,9.583,3.971 l21.101,21.1l50.623-50.623c2.56-2.56,5.963-3.969,9.583-3.969c3.62,0,7.023,1.409,9.583,3.969 C156.146,65.765,156.146,74.362,150.862,79.646z"/></svg></span>';
		        $('.room-name-'+(roomNumber+1)).html(icon + roomName + ' - $'+ attributes.roomRate).parent('.tab-wrap').parent('a').addClass('has-room');
		    },
		    error: function (XMLHttpRequest, textStatus) {
		        button.after('<p class="booking-error">'+textStatus+'</p>')

		    }
		});
	});

	$(document).on('keypress', '#code' , function (e) {
	  	if (e.which == 13) {
	    	var attributes = new Object;
	    	attributes.code = $(this).val();

	    	var data = {
		   		action: 'protel/default/add-coupon',
		    	attributes: attributes
			};

			data[window.csrfTokenName] = window.csrfTokenValue;

			$.ajax({
				type: "post",
				url: "",
				data: data,
			    success: function (response) {
			    	response = JSON.parse(response);
		
			    	if (response.success == true) {
			    		$('.booking-error').html("<strong>Coupon added</strong><span>"+response.message+"</span>");
			    	}
			    	else {
			    		$('.booking-error').html("<strong>Error adding coupon</strong><span>"+response.message+"</span>");
			    	}

			    	
			    },
			    error: function (XMLHttpRequest, textStatus) {
			        $('.booking-error').html("<strong>"+textStatus+"</strong>");
			    }
			});

			$('.booking-error').show();

	  	}
	});


	$(document).on('click', '.add-extra-to-room' , function (e) {
		e.preventDefault();
		var button = $(this);
		var overlay = $(this).closest('.overlay');

		var buttonText = button.html();


		//TO DO: Booking Base and Booking Limit - how many to add and limits on adding

		var attributes = new Object;
		attributes.roomBookingId = button.attr('data-bookingid');
		attributes.extraId = button.attr('data-extraid');
		attributes.name = button.attr('data-name');
		
		var data = {
		    action: 'protel/default/add-extra',
		    attributes: attributes
		};

		data[window.csrfTokenName] = window.csrfTokenValue;
		$(this).addClass('loading');
		$(this).prop('disabled', true);
		$(this).text('Loading...');
		$.ajax({
			type: "post",
			url: "",
			data: data,
		    success: function (response) {
		    	var details = new Array();
		    	details['extras'] = [response];
		    	updateBookingCookie(details, 'extras');
		    	updateBookingNav();
		    	refreshSidebarCart();
		    	button.addClass("disabled");		    	

		    	button.removeClass('loading');
				button.prop('disabled', false);
				button.html(buttonText);

				if (overlay.length == 0) {
					button.hide();
					console.log(button);
					//button.text("Added");
				}
				
				var totalButtons = overlay.find('.add-extra-to-room').length;
				var totalDisabledButtons = overlay.find('.add-extra-to-room.disabled').length;

				if (totalButtons === totalDisabledButtons) {
					overlay.prev().hide();
				}

		    },
		    error: function (XMLHttpRequest, textStatus) {
		        button.after('<p class="booking-error">'+textStatus+'</p>')
		    }
		});

	});


	$(document).on('click', '.remove-extra-from-cart' , function (e) {
		e.preventDefault();
		var button = $(this);

		var attributes = new Object;

		attributes.roomBookingId = button.attr('data-bookingid');
		attributes.extraId = button.attr('data-extraid');
		attributes.name = button.attr('data-name');

		var data = {
		    action: 'protel/default/delete-extra',
		    attributes: attributes
		};

		data[window.csrfTokenName] = window.csrfTokenValue;

		$.ajax({
			type: "post",
			url: "",
			data: data,
		    success: function (response) {
		    	edited = true;
		    	existingDetails = removeBookingCookieItem('extras', attributes.extraId);
		    	saveEncodedCookie(existingDetails);
		    	refreshSidebarCart();
		    	var button = $('.add-extra-to-room[data-bookingid="'+ attributes.roomBookingId +'"][data-name="'+ attributes.name +'"]');
		    	var overlay = button.closest('.overlay');
		    	
		    	button.removeClass('disabled');
				button.text("Add to Booking");
		    	var totalButtons = overlay.find('.add-extra-to-room').length;
				var totalDisabledButtons = overlay.find('.add-extra-to-room.disabled').length;

				if (totalButtons > totalDisabledButtons) {
					overlay.prev().show();
				}

				if (overlay.length == 0) {

					button.show();
				}
		    },
		    error: function (XMLHttpRequest, textStatus) {
		        button.after('<p class="booking-error">'+textStatus+'</p>')
		    }
		});

	});


	$(document).on('change', 'select[name=arrival_time]', function(e) {
		if (parseInt($(this).val()) > 5) {

			if ($(this).parent().next().hasClass('warning') == false) {
				$(this).parent().after('<div class="warning"><strong>Reception desk will be closed after 6pm</strong><br /><span>The Check-In procedure will be provided as part of your Reservation Confirmation if you are arriving after 6pm</span></div>')
			}
			
		}
		else {
			$(this).parent().next('.warning').remove();
		}
	});

	
	$(document).on('change','form label input[required="required"]',function(e){
		$('label.same-booker.selected-child').each(function(){

			var guestFields = $(this).parent('.booked-room').next('.guest-fields');
	
				var countryData = $("#booker-telephone").intlTelInput("getSelectedCountryData");
				guestFields.find('input.telephone').intlTelInput('setCountry',countryData.iso2);
				guestFields.find('input[name^=guest-email]').val($('input[name=email]'));
				guestFields.find('select[name^=guest-salutation]').val($('select[name=salutation]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-firstname]').val($('input[name=firstname]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-lastname]').val($('input[name=lastname]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-email]').val($('input[name=email]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-mobile]').val($('input[name=mobile]').val()).prev('#placeholder').addClass('active-focus-filled');
				//guestFields.find(' input[name^=guest-country]').val($('input[name=country]').val());
				var country = $('input[name=country]').val();
				guestFields.find('input.country').countrySelect("setCountry", country);
				guestFields.find('input[name^=guest_country_code]').val($('input#country_tel').val()).prev('#placeholder').addClass('active-focus-filled');


		});
	});




	$(document).on('click', '#add-basket-notes', function (e) {

		e.preventDefault();

		if ($("#notes-form").valid()) {
			console.log("notes-form valid");
			//$(this).find('div').addClass('loading');
			//$(this).prop('disabled', true);
			//$(this).find('div').text('Loading...');
			var arrivalTime = $('select[name=arrival_time]').val();
			var reason = $('select[name=reason]').val();
			var additionalRequests = $('textarea[name=additional_requests]').val();
			var howHearOption = $('select[name=how-did-you-hear]').val();
			//var howHearExtra = $('textarea[name=how-did-you-hear]').val();



			var attributes = new Object;

			attributes.ArrivalTime = arrivalTime;
			attributes.TravelReason = reason;
			
			attributes.CommChannel = howHearOption;
			//attributes.CommChannelExtraInfo = howHearExtra;
			attributes.AdditionalRequests = additionalRequests;


			var data = {
			    action: 'protel/default/add-notes',
			    attributes: attributes
			};

			data[window.csrfTokenName] = window.csrfTokenValue;
			console.log(data);
			$.ajax({
				type: "post",
				url: "",
				data: data,
			    success: function (response) {
			    	//Redirect to finalise
					var arrivalTime = $('select[name=arrival_time]').val();
					console.log(arrivalTime);
					updateBookingCookie(attributes,'notes');
					$("ul.steps li.active.current-step").addClass("enabled").find(".detail").text(arrivalTime + " Arrival");
			    	var details = Cookies.get('bookingDetails');
					details = JSON.parse(window.atob(details));
					console.log(details);
    				window.location.href = "/book/finalise?basket_id="+details.basketId;
			    },
			    error: function (XMLHttpRequest, textStatus) {
			       
			    }
			});
			
		}
		else {
			$(this).find('div').addClass('continue');
			$(this).find('div').removeClass('loading');
			//$(this).prop('disabled', true);
			$(this).find('div').text('Continue');
		}

	  	return false;
		
	
		
	});


	$("#notes-form").validate({
	  	success: "valid",
	    errorElement: 'span',
	  	errorPlacement: function(error, element){
	  		if (element.is('input') || element.is('select')) {
	  			element.parent().addClass('has-error') ;
	  			error.appendTo(element.parent());
	  		} 
	  		else {
	  			return true
	  		}
	  	} ,
		rules: {
			arrival_time: "required",
			reason: "required",
		},
		messages: {
			arrival_time: "Please choose your arrival time.",
			reason: "Please enter your reason for travel.",
		}
		  		
  	});

  	function cc_format(input, format, sep) {
	    var output = "";
	    var idx = 0;
	    for (var i = 0; i < format.length && idx < input.length; i++) {
	        output += input.substr(idx, format[i]);
	        if (idx + format[i] < input.length) output += sep;
	        idx += format[i];
	    }

	    output += input.substr(idx);

	    return output;
	}




	//Finalise Booking
	$(document).on('keyup', '#booker-form input[name="card_number"]' , function (e) {
		    var current_val = $(this).val().replace(/\D/g, "");

		    if (current_val.length > 0) {
		        current_val = cc_format(current_val, [4, 4, 4, 4,], "-");
		    }
		    
		    $(this).val(current_val);
	});


	$(document).on('focusout', '#booker-form input, #guest-form input, #email-form input', function (e) {
		showTermsBox();
	});

	function completeBooking(e){

			e.preventDefault();
			var $this = $('#complete-booking');
			emailFormValidity = $("#email-form").valid();
			bookerFormValidity = $("#booker-form").valid();
			guestFormValidity = $("#guest-form").valid();
			termsFormValidity = $("#terms-form").valid();
	
	
			if($('.complete-booking').prop('disabled') || $('.complete-booking').hasClass('disabled')) {
				return false;
			}
	
			if (!$('.finialise-payment-form').is(':visible')) {
				return false;
			}
	
			/*if ($("form#booker-form, form#guest-form").valid()) {
				$('#terms-box').show();
				$('.complete-booking').removeClass('disabled');
			}
			else {
				return false;
			}*/
	
			if (emailFormValidity && bookerFormValidity && guestFormValidity && termsFormValidity ) {
				//$('.complete-booking').removeClass('disabled');
				$("form.with-numbers.summary-form#terms-form #booking-error").hide();
				$this.removeClass('error');
				submitBooking();
				//alert("Booking Submitted");			
			}
			else {
				$("form.with-numbers.summary-form#terms-form #booking-error").show();
				$this.addClass('error');
				
				if ($(window).width() < 675){

					var target = $('#booking-error');
					var offset = target.offset().top;
				} else {

					var target = $('.error:first');
					var offset = 0;
				}
console.log(target);
console.log(target.offset().top);				
				if (target.length) {
					$('html,body').animate({
						scrollTop: offset
					}, 1000);
					return false;
					}
	
				return false;
			}
			
	}

	function showTermsBox(){
		var complete = true;		

		$('#booker-form input').each(function () {
			var required = $(this).attr('required');
			if (required != null && required != false) {
				if ($(this).val() == "") {
					complete = false;
				}
			}
		});

		$('#guest-form input').each(function () {
			var required = $(this).attr('required');
			if (required != null && required != false) {
				if ($(this).val() == "") {
					complete = false;
				}
			}
		});

		$('#email-form input').each(function () {
			var required = $(this).attr('required');
			if (required != null && required != false) {
				if ($(this).val() == "") {
					complete = false;
				}
			}
		});

		if (complete) {
			return false;
		}else{
			$('#terms-box input').prop('checked',false);
		}
	}

	$(document).on('change', '.same-booker', function() {
		//showTermsBox();
		//bookerFormValidity = $("#booker-form").valid();
		//emailFormValidity = $("#email-form").valid();
		//guestFormValidity = $("#guest-form").valid();
		//termsFormValidity = $("#terms-form").valid();

	});

	/*$(document).on('click', '#complete-booking' , function (e) {
		e.preventDefault();
		emailFormValidity = $("#email-form").valid();
		bookerFormValidity = $("#booker-form").valid();
		guestFormValidity = $("#guest-form").valid();
		termsFormValidity = $("#terms-form").valid();


		if($('.complete-booking').prop('disabled') || $('.complete-booking').hasClass('disabled')) {
			return false;
		}

		if (!$('.finialise-payment-form').is(':visible')) {
			return false;
		}

		/*if ($("form#booker-form, form#guest-form").valid()) {
			$('#terms-box').show();
			$('.complete-booking').removeClass('disabled');
		}
		else {
			return false;
		}*/

		/*if (emailFormValidity && bookerFormValidity && guestFormValidity && termsFormValidity ) {
			//$('.complete-booking').removeClass('disabled');
			$("form.with-numbers.summary-form#terms-form #booking-error").hide();
			$(this).removeClass('error');
			//submitBooking();
			//alert("Booking Submitted");			
		}
		else {
			$("form.with-numbers.summary-form#terms-form #booking-error").show();
			$(this).addClass('error');
			var firstError = $('.error:first');
			var target = $('#booking-error');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
				}

			return false;
		}
		
		

	});*/

	$('#terms-form').validate({
		success: "valid",
	    errorElement: 'span',
	  	errorPlacement: function(error, element){
	  		if (element.is('input')) {
	  			element.parent().addClass('has-error') ;
	  			error.appendTo(element.parent());
	  		} else {
	  			return true
	  		}
	  	},
	  	rules: {
			terms: "required",
		},
		messages: {
			terms: "Please accept the Terms and Conditions."
		}
	});


	$.validator.addMethod("expiryDate", function (value, element) {
		var patt = new RegExp("^[0-9][0-9][\/][0-9][0-9]$");
	    return this.optional(element) || patt.test(value);
	}, "Please enter your card expiry in MM/YY e.g. 05/24");

  	$("form#booker-form, form#guest-form").each(function(e){
		$(this).validate({
		  	success: "valid",
		    errorElement: 'span',
		  	errorPlacement: function(error, element){
		  		if (element.is('input')) {
		  			element.parent().addClass('has-error') ;
		  			error.appendTo(element.parent());
		  		} else {
		  			return true
		  		}
		  	} ,
			rules: {
				firstname: "required",
				lastname: "required",
				/*street: "required",
				city: "required",
				zip: "required",
				country: "required",*/
				country_code: "required",
				mobile: "required",
				card_name: "required",
				card_number:"required",
				expiry: {
					required: true,
					expiryDate: true
				},
				cvv:"required"
			},
			messages: {
				firstname: "Please enter your first name.",
				lastname: "Please enter your last name.",
				street: "Please enter your address.",
				city: "Please enter your suburb.",
				zip: "Please enter your postcode.",
				country: "Please select your country.",
				country_code: "Please enter the country code for your phone number.",
				mobile: "Please enter your mobile phone number.",
				card_name: "Please enter the name on your credit or debit card",
				card_number:"Please enter your card number",
				expiry:"Please enter your card expiry in MM/YY e.g. 05/24",
				cvv:"Please enter your CVV"
			},
			submitHandler: function(form) {
			  //form.submit();
			},
			invalidHandler: function(event,Handler){

			}
		})

  	});

	function submitBooking() {
console.log("submit booking");
		

		//var $completeBookingButton = $('.complete-booking').addClass('disabled');
		//$completeBookingButton.find('div').addClass('loading');
		//$completeBookingButton.prop('disabled', true);
		/*if(!$completeBookingButton.prop('disabled')){*/
			var finaliseButton = $('.complete-booking');

			finaliseButton.find('div').text('Processing...');
			finaliseButton.find('div').addClass('loading');

			guestInfo = [];
			roomGuests = [];
			guestData = new Object;
			for (var n = 1; n <= 4; ++ n){
				$('.room-guests-' +n).each(function( index ){
					$(this).find("input, select").each(function(){
						var inputNames = $(this).attr('name').split("__");
						var inputName = inputNames[0];
						guestData[inputName] = $(this).val();
					});
					var guestCC = $(this).find('.guest-fields').find('input.telephone').intlTelInput("getSelectedCountryData");
					guestData["guest_country_code"] = "+" + guestCC.dialCode;
					console.log(guestCC);
					roomGuests.push(guestData);	
					guestData = {};		
				});
				guestInfo.push(roomGuests);
				roomGuests = [];

			}

			var attributes = new Object;

			$(".finalise input, .finalise select").each(function () {

				var val = $(this).val();
				if ($(this).attr('name') == "card_number") {
					val = val.replace(/\s/g,"");
				}
				attributes[$(this).attr('name')] = val;
			});

			//replace with selected country code
			var bookerCC = $("#booker-telephone").parent('.iti').find('.iti__selected-dial-code').text();
			console.log(bookerCC);
			attributes["country_code"] = bookerCC;
			var data = {
				action: 'protel/default/submit-booking',
				attributes: attributes,
				guestInfo: guestInfo
			};

			data[window.csrfTokenName] = window.csrfTokenValue;
			//return false;

			$.ajax({
				type: "post",
				url: "",
				data: data,
				dataType: 'json',
				success: function (response) {

					if (response.success == false || response.success == "false") {
						$('.complete-booking').removeClass('disabled');
						finaliseButton.find('div').text('Finalise Payment');
						$('.payment-error').html("<strong>Error completing your booking</strong><span>Some rooms or items in your cart are no longer available</span>");
						$('.payment-error').show();
						//Payment error then show

						//Cart or another type of error then
						//Clear the cookie and go back to guests
						//resetBooking();

					}
					else {

						//Redirect to thanks page and clear booking
						//Cookies.remove('bookingDetails');
						
						window.location.href = response.message

					}

				},
				error: function (XMLHttpRequest, textStatus) {
					$('.complete-booking').removeClass('disabled');
					finaliseButton.text('Finalise Payment');
					$('.payment-error').html("<strong>Error completing you booking</strong><span>"+ textStatus + "</span>");
					$('.payment-error').show();
				}
			});
		/*}*/
	} 

  	$(".finalise #email-form").validate({
	  	success: "valid",
	    errorElement: 'span',
	  	errorPlacement: function(error, element){
	  		if (element.is('input')) {
	  			element.parent().addClass('has-error') ;
	  			error.appendTo(element.parent());
	  		} else {
	  			return true
	  		}
	  	} ,
		rules: {
			email: {
				required: true,
				email: true
			}
		},
		messages: {
		 	email: "Please enter a valid email address"
		},
		submitHandler: function(form) {
	  		//$('.submit-email-step').hide();
			//$('.finialise-payment-form').show();
			console.log("#email-form");
		}
  	});


  	function refreshSidebarCart() {
		$.ajax({
			type: "get",
			url: "/ajax/reservationsummary",
		    success: function (response) {
		    	$('.reservation-summary-load').html(response);
		    	
		    },
		    error: function (XMLHttpRequest, textStatus) {
		       console.log('Error loading cart');
		    }
		});

	}

	function bookingSuccess() {
		
		
	}

	function showBookingNotAvailable(event) {
		$('html, body').css({
			overflow: 'hidden',
			height: '100%'
		});

		$.blockUI({
			message: '<div class="modal-wrapper"><p>We apologise for this inconvenience however we are currently experiencing technical issues with our Online Reservations.</p>' +
			'<p>Please call our Hotel directly on <a href="tel:+61865003950">+61 8 6500 3950</a> and we will be able to look after your reservation enquiry.</p>' +
			'<p>Click <a href="/">here</a> to go back to the homepage.</p>' +
			'<span class="button b-close"><span>&times;</span></span></div>',
		});

		$('.blockOverlay').click($.unblockUI);

		return false;
	}
});

// Finalise your booking - Fields
$(document).ready(function() {
	
	var fieldSelectors = '.finalise input, .finalise select, .summary-form select, .summary-form textarea';

	$(fieldSelectors).each(function(i, e) {
		if ($.trim($(this).val()).length > 0) {
			$(this).prev('#placeholder').addClass('active-focus-filled');
		}
	})

	$(document).on('focus', fieldSelectors, function() {
		focusField($(this))
	}).on('blur', fieldSelectors, function() {
		blurField($(this));
	});

	

	function focusField(param) {
		
		if (!param.prev('#placeholder').hasClass('active-focus-filled')) {
			param.prev('#placeholder').addClass('active-focus-filled');
			if (param.hasClass('custom-select')) {
				
				param.attr('size',1);
				
			}
		} else {
			return false;
		}
	}
	function blurField(param) {
		if ($.trim(param.val()).length > 0) {
			focusField(param);
		} else {
			param.prev('#placeholder').removeClass('active-focus-filled');
		}
	}
});

// Add Coupon button
/*$(document).ready(function() {

	// show coupon field
	$('.reservation-summary .add-coupon').on('click', function() {
		var $this = $(this);
		$this.hide();
		$('.coupon-field-group').addClass('show');
	});

	// submit coupon field
	$('.coupon-code-submit').on('click', function() {
		console.log('Will do some api request');
		$(this).parent('.coupon-field-group').removeClass('show');
		$(this).prev('input').val('');
		$('.coupon-discount').show();
	});

	// Remove applied coupon
	$('.remove-coupon').on('click', function() {
		console.log('Will do some api request');
		$('.coupon-discount').hide();
		$('.add-coupon').show();
	});
});*/

// Guests details
$(document).ready(function() {

	$(".finalise #guest-form").validate({
	  	success: "valid",
	    errorElement: 'span',
	  	errorPlacement: function(error, element){
	  		if (element.is('input') ) {	  			
	  			element.parent().addClass('has-error') ;
	  			error.appendTo(element.parent());
	  		} else {
	  			return true;
	  		}
	  	}
  	});

  	$(document).on('click', '.guest-close-button', function (e) {
  		e.preventDefault();
  		$(this).parent().parent().next('.add-additional-guest').removeClass('hidden');
  		$(this).closest('.room-guests').remove();
  	});

	$(document).on('change', '.booked-room .same-booker', function(e) {
		//console.log($("#booker-form").valid());
		//bookerFormValidity = $("#booker-form").valid();
		//emailFormValidity = $("#email-form").valid();
		//guestFormValidity = $("#guest-form").valid();
		//termsFormValidity = $("#terms-form").valid();
		console.log(e.target.checked);
		console.log($(this).parent('.booked-room').parent('.room-guests').find('.error').length);
		if (!e.target.checked) {
			$(this).removeClass('selected-child');

		
		}
		var bookerCC = $("#booker-telephone").parent('.iti').find('.iti__selected-dial-code').text();
		//var bookerCC = $('#booker-telephone').getSelectedCountryData();

		
			var guestFields = $(this).parent('.booked-room').next('.guest-fields');
			
			if (e.target.checked ) {

				// Fill in all of the form fields
				/*var countryData = $("#booker-telephone").intlTelInput("getSelectedCountryData");
				console.log(countryData);
				guestFields.find('input.telephone').intlTelInput('setCountry',countryData.iso2);
				console.log("copying fields");
				guestFields.find('input[name^=guest-email]').val($('input[name=email]'));
				guestFields.find('select[name^=guest-salutation]').val($('select[name=salutation]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-firstname]').val($('input[name=firstname]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-lastname]').val($('input[name=lastname]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-email]').val($('input[name=email]').val()).prev('#placeholder').addClass('active-focus-filled');
				guestFields.find('input[name^=guest-mobile]').val($('input[name=mobile]').val()).prev('#placeholder').addClass('active-focus-filled');
				//guestFields.find(' input[name^=guest-country]').val($('input[name=country]').val());
				var country = $('input[name=country]').val();
				guestFields.find('input.country').countrySelect("setCountry", country);
				guestFields.find('input[name^=guest_country_code]').val($('input#country_tel').val()).prev('#placeholder').addClass('active-focus-filled');*/

				// OR

				// Hide the form
				guestFields.hide();
			} else {
				guestFields.show();
				guestFields.find('input[name^=guest]').val("");
				guestFields.find('select[name^=guest]').val("");
				guestFields.find(' input[name^=guest-country]').val("Australia");
				var country = "Australia";
				guestFields.find(' input.country').countrySelect("setCountry", country);
				guestFields.find(' input[name^=guest_country_code]').val("+61");
				guestFields.find('input.telephone').intlTelInput('setCountry','au');
			}
			/*$('.booked-room .same-booker').parent('.booked-room').next('.guest-fields')
				.not($(this).parent('.booked-room').next('.guest-fields'))
				.show();
			$('.booked-room .same-booker').not(this)
				.parent('.booked-room').parent('.room-guests').removeClass('same-as-booker');
			$('.booked-room .same-booker input')
				.not($(this).find('input'))
				.prop('checked', false);*/

		
	});

	$('.add-additional-guest').on('click', function() {
				
		var roomName = $(this).data('room-name');
		console.log(roomName.slice(-1));
		if (roomName.slice(-1) == 's') {
			roomName = roomName.slice(0,-1);
		}
		var roomIndex = $(this).data('room-index');
		var roomId = $(this).data('room-id');
		var existingGuestsCount = $('.room-guests-'+roomIndex).length + 1;
		var guestCount = $(this).data('guest-count');

		var incrementedGuest = existingGuestsCount  ;

		var fieldNameSuffix = `__room_` + roomIndex + `_guest_` + incrementedGuest  ;


		var currentRoomNumber = $(this).data('room-number');
		if( existingGuestsCount == guestCount ){
			$(this).addClass("hidden");
		}

		var template = `
			<div class="room-guests room-guests-`+ currentRoomNumber +`">
				<div class="booked-room">
				<span>Guest ` + existingGuestsCount + `</span>
					<a href="#" class="guest-close-button">x</a>
				</div>
				<div class="guest-fields">
					<div class="label-2-3">
						<label>
							<span>01.</span>
							<span id="placeholder">Title</span>
							<select name="guest-salutation">
								<option></option>
								<option value="Miss">Miss</option>
								<option value="Ms">Ms</option>
								<option value="Mr &amp; Mrs">Mr &amp; Mrs</option>
								<option value="Mrs">Mrs</option>
								<option value="Mr">Mr</option>
								<option value="Dr">Dr</option>
							</select>
						</label>
						<label>
							<span>02.</span>
							<span id="placeholder">First Name *</span>
							<input type="text" name="guest-firstname` + fieldNameSuffix +  `" autocomplete="given-name" required>
						</label>
					</div>

					<label class="">
						<span>03.</span>
						<span id="placeholder">Last Name *</span>
						<input type="text" name="guest-lastname` + fieldNameSuffix +  `" autocomplete="family-name" required> 
					</label>

					<div class="mb-0">
						<label>
							<span>04.</span>
							<span id="placeholder">Email Address (optional)</span>
							<input type="email" name="guest-email` + fieldNameSuffix +  `">
						</label>						
					</div>
					<!--<label>
						<span>05.</span>
						<span id="placeholder" class="active-focus-filled">Country</span>
						<input type="text" class="guest-country country " value="Australia" id="guest_country`+ existingGuestsCount +`" name="guest-country` + fieldNameSuffix +  `" autocomplete='country-name'  />
					</label>-->
					<div class="mb-0">										
						<!--<label>
							<span>06.</span>
							<span id="placeholder" class="active-focus-filled">Country Code</span>
							<input class="country-code" type="text" name="guest_country_code` + fieldNameSuffix + `"  id="guest_country_tel" value="+61" placeholder="Country Code" >
						</label>-->
						<label>
							<span>05.</span>											
							<span id="placeholder">Mobile Number (optional)</span>
							<input type="tel" name="guest-mobile` + fieldNameSuffix +  `" class="telephone" placeholder="">
						</label>
					</div>
				</div>
			</div>
		`;
		$(template).insertBefore($(this));

		loadCountrySelectFields();

		$(".country-select").prev('span').hide() ;

	});
});

// Make finalise booking right `Your reservation` panel sticky
$(window).on('scroll', function(e) {
    if ($(window).scrollTop() > 0) {
        if (!$('.reservation-summary').hasClass('scrolled')) {
        	$('.reservation-summary').addClass('scrolled')
        } else {
        	return false;
        }
    } else {
    	$('.reservation-summary').removeClass('scrolled');
    }
});

$(document).ready(function() {
	var emailFormValidity, bookerFormValidity,guestFormValidity,termsFormValidity;

	$(document).on('click', '.country-select', function() {

		var telCode = $(this).closest('.guest-fields').find(".country-list .active").attr('data-country-tel') ;

		$(this).closest('.guest-fields').find('input.country-code').val( "+" + telCode );

	});

	/*$(document).on('change', '#terms' , function (e) {
		if ($(this).is(':checked') == true) {
			emailFormValidity = $("#email-form").valid();
			bookerFormValidity = $("#booker-form").valid();
			guestFormValidity = $("#guest-form").valid();
			console.log(emailFormValidity);
			console.log(bookerFormValidity);
			console.log(guestFormValidity);
		}
		
		if (emailFormValidity && bookerFormValidity && guestFormValidity &&  $(this).is(':checked') == true ) {
			            $('.complete-booking').removeClass('disabled');            
			        }
			        else {
			            $('.complete-booking').addClass('disabled');
			        }
	});*/
});
function loadSelects() {


	/*if ( $('.sidebar select ').length ) {

		$('.sidebar select').select2({
		  selectOnClose: true ,
		  width: "100%",
		  minimumResultsForSearch: Infinity ,
		}).data('select2').$dropdown.addClass('sidebarred');

	}
	$('b[role="presentation"]').hide();*/
} 


loadSelects();
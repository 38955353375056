// function loadPosts(slug) {


// 	var slug = slug ;
// 	var builtUrl = '/blog/category/' + slug ;


// 	$.get( builtUrl , function( data ) {
// 		$( ".ajax-result" )
// 			.hide()
// 			.html( data )
// 			.fadeIn();
// 		// console.log(builtUrl );
// 	});

// }

// $(document).on("click",".blog-filter a",function() {
// 	// do a thing
// 	event.preventDefault();
// 	var slug = $(this).data('slug');
// 	loadPosts(slug);
// });

$(window).on("resize load", function () {


	// Change logo out for mobile 
	var img = $(".home #logo");

	if ($(window).width() < 768) {
		if ($('header').hasClass('inverted')) {
			img.attr("data" , "/assets/images/logo-inner-black.svg").css({"max-width" : "184px" }) ;
		}
		else {
			img.attr("data" , "/assets/images/logo-inner-white.svg").css({"max-width" : "184px" }) ;
		}
		
	}
	else {
		if ($('header').hasClass('inverted')) {
			img.attr("data" , "/assets/images/logo-inverted.svg").css({"max-width" : "184px" }) ;
		}
		else {
			img.attr("data" , "/assets/images/logo.svg").css({"max-width" : "184px" }) ;
		}
	} 



});



$(window).on('load', function(){

	$( '.menu-nav-trigger' ).click(function(e) {
console.log("menu nav trigger");
		$('.full-menu').toggleClass('open').css({"visibility" : "visible"});
		$('body').toggleClass('menu-open');
		$(this).find('.menu-nav-icon').toggleClass('is-open');

		if ( $('header').hasClass('at-footer') ) {
			
		} else {
			$('header').removeClass('at-footer');
			$('header .branding').removeClass('trans');
			$('.home-pagination').css("display" , "flex" );
		} ;

		if ( $('body').hasClass('menu-open') ) {
			if ( !$('header li.branding').hasClass('trans')) {
				$('header li.branding').addClass('trans show-on-close');
			};
			if ( !$('header li.close').hasClass('trans')) {
				$('header li.close').addClass('trans show-on-close');
			}
		} else {
			$('header li.close').removeClass('trans show-on-close');
		}

		if ($(window).width() > 768) {

			$(".full-menu .branding, .full-menu nav.menu > div ").each(function(index) {
				$(this).delay(500*index)
				.css({"display" : "flex"})
				.hide()
				.fadeIn(250)
				.addClass('ready')
				;
			});

		}  else {
			$(" .full-menu nav.menu > div ").each(function(index) {
				$(this).delay(400*index)
				.css("display", "block")
				.hide()
				.fadeIn(100)
				.addClass('ready');

			});
		}
	});

});




// Snap Scroll

$(document).ready(function () {

	var bgSwiper = new Swiper('.bg-slider.swiper-container', {
		// lazy: true, 
		speed: 600,  
		parallax: true, 
		loop: true ,
		autoplay: {
	    	delay: 3000,
	  	},
		pagination: {
		  clickable: 'true' ,
		  el: '.swiper-pagination',
		}

	});


  	var height = window.innerHeight ;
console.log(height);
  	var slider_array = [];
        var el = $('.home-swiper')
        el.find('[data-title]').each(function () {
            slider_array.push($(this).attr('data-title'));
    });

  	if ($(window).width() >= 1025) {


  		var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;


  		if ( isIE11 != true  ) {
  			$("body.home").css({
  				"overflow": "hidden"
  			});
  		}

		$('.home-pagination').css({
			'opacity' : '1' ,
			'display'    : 'flex'
		});

	    var homeSwiper = new Swiper('.home-swiper', {
	      spaceBetween: 0,
	      speed: 600,
	      keyboard: true,
	      mousewheel: {
	      	invert: false,
	      	sensitivity: .5,
	      },
	      history: {
		    replaceState: true,
		    key: 'home'
		  },
	      autoPlay: false,
	      centeredSlides: true,
	      touchReleaseOnEdges: true,
	      height: height, 
	      autoHeight: true, 
	      updateOnWindowResize: true,
	      direction: 'vertical',
	      on: {
	      	reachEnd: function() {
	      		$('.home-pagination').hide();
	      	},
	      	transitionStart: function(index,elem) {

	      		var index = $(this)[0].realIndex ;
	      		var slides = $(this)[0].slides  ;
	      		var element = slides[index] ;
				if ($(element).attr('id') == 'home') {
					//bgSwiper[0].slideTo(1);

				}

	      		if ($(element).attr('id') == 'footer') {
      				$('header').addClass('at-footer');
	      		} else {
	      			$('header').removeClass('at-footer');
	      		}


	      	},
	      	transitionEnd: function( index, elem ) {

	      		var index = $(this)[0].realIndex ;

	      		var slides = $(this)[0].slides  ;

	      		var element = slides[index] ;

	      		resetHeader();

	      		if ( $(element).attr('data-color').length >= 1 ) {
	      			$('.home-pagination.swiper-pagination-clickable.swiper-pagination-bullets').addClass('inverted');
	      			invertHeader();
	      		} else {
	      			$('.home-pagination.swiper-pagination-clickable.swiper-pagination-bullets').removeClass('inverted');
	      			resetHeader();
	      		}

	      		//If the pagination got hidden on the footer, show again when we slide back up
	      		if ($(element).attr('id') != 'footer' && $(window).width() >= 1440) {
	      			$('.home-pagination').show().css({
	      				'display' : 'flex' ,
	      				'opacity' : '1'
	      			});
	      			$('header').removeClass('trans');
      				
	      		}
	      		else {
	      			//Hide the logo on the footer too
	      			$('header .branding').addClass('trans');
	      			$('header').addClass('trans');
	     			
	      		}

	      		if ($(element).attr('id') == 'home') {
	      			//Hide the logo on the home too
	      			$('header .branding').addClass('trans');
	      			$('.home-pagination').css({
	      				'opacity' : '1' ,
	      				'display'    : 'flex'
	      			});

	      		}

	      		if ($(element).prev().attr('id') == 'home' ) {

	      			//homeSwiper.update();
	      			//homeSwiper.updateAutoHeight();
 

	      		}
	      	}
	      },
	      pagination: {
	        el: '.home-pagination',
	        clickable: true,
	        renderBullet: function (index, className, item, current) {

	        	//Helps with showing and hiding at the right times.
		    	var additionalClasses = "";

		    	if (index == 0) {
		    		additionalClasses = "first";
		    	}

		    	if (index == slider_array.length) {
		    		additionalClasses = "last";
		    	}

	          	return '<span class="' + className + " "+ additionalClasses + '"><span class="pagination-text '+ additionalClasses +' ">' + slider_array[index] + '</span></span>';

	        },
	      },

	    });

	}
	else {

		 var homeSwiper = new Swiper('.home-swiper', {
	      spaceBetween: 0,
	      speed: 600,
	  	  mousewheel: {
	      	invert: false,
	      	sensitivity: 50,
	      },
	      history: {
		    replaceState: true,
		    key: 'home'
		  },
		  // freeMode: true, 
	      autoPlay: false,
	      centeredSlides: true,
	      touchReleaseOnEdges: true,
	      // height:  , 
	      direction: 'vertical',
	      autoHeight: true, 
	      updateOnWindowResize: true,
	      on: {
	      		transitionStart: function() {
	      			$('header .branding').addClass('trans');
	      		},
      			transitionEnd: function() {

		      		var button = $('.booking-toggle') ;

		      		var index = $(this)[0].realIndex ;

		      		var slides = $(this)[0].slides  ;

		      		var element = slides[index] ;
		    
console.log($(element).attr('data-color').length);
		      		if ( $(element).attr('data-color').length >= 1 ) {
		      			invertHeader();
		      		} else {
		      			resetHeader();
		      		}

		      		if ($(element).attr('id') == 'footer') {
		      			//Hide the logo on the footer too
		      			$('header .branding').addClass('trans');
		      			$('header').addClass('trans');
		      			$(this)[0].params.noSwiping = true   ;
		      			//$(this).update();
		      			
		      			$('.wrap').removeClass('clamped') ;
		      			$('.wrap').addClass('at-footer');

		      		} else {
		      			$(this)[0].params.noSwiping = false   ;
		      			$(this)[0].params.autoHeight = true;
		      			// $(this)[0].params.freeMode = false ; 
		      			// $(button).fadeOut();
		      			$('.wrap').addClass('clamped') ;
		      			$('.wrap').removeClass('at-footer');
		      			$('header').removeClass('trans');
		      		}

		      		if ($(element).attr('id') == 'home') {
		      			//Hide the logo on the home too
		      			$('header .branding').addClass('trans');
		      		}

		      		if ($(element).prev().attr('id') == 'home' ) {
		      			// homeSwiper.update();
		      			// homeSwiper.updateAutoHeight();
		      		}


      			}
		   }
		})

	}

	if ($('.home-swiper').length > 0) {

		homeSwiper.update();
	}
	

	$("a.next-section > img").click(function() {
		homeSwiper.slideNext();
	});


	// Change haeder image colours

	function svgPathFill( elem, colour ) {

		if ($("body").hasClass("home") == true) {


			if ($(window).width() >= 1279) {
				var element = document.getElementById(elem);
				if (element != null) {
					var svgDoc = element.contentDocument;
					var svgItem = svgDoc.getElementsByTagName('path');
					var svgGroup = svgDoc.getElementsByTagName('g');
					// console.log(svgItem);
					$(svgItem).attr("fill", colour);
					$(svgGroup).css({ filter : "none"});
				}


			}

		}

	}




	function switchLogoColour(colour) {

		if ($(window).width() < 760) {
			var img = $(".home #logo");
			img.attr("data" , "/assets/images/logo-inner-"+ colour +".svg") ;

		} 
		else {
			var logoType = "";
			if (colour == "black") {
				logoType = "-inverted";
			}
			var img = $(".home #logo");
			img.attr("data" , "/assets/images/logo"+ logoType +".svg") ;
		}
	}


	function invertHeader() {

		$('header > ul img, header > ul object ').addClass('inverted');

		svgPathFill('logo', '#000');
		svgPathFill('logoInstagram', '#000');
		svgPathFill('logoFacebook', '#000');
		svgPathFill('logoTripAdvisor', '#000');
		$('header').addClass('inverted');
		$('.thermometer , .pagination').addClass('inverted');
		switchLogoColour('black');
		$('header .branding').removeClass('trans');

		

	}

	function resetHeader() {

		$('header > ul img , header > ul object ').removeClass('inverted');

		svgPathFill('logo', '#fff');
		svgPathFill('logoInstagram', '#fff');
		svgPathFill('logoFacebook', '#fff');
		svgPathFill('logoTripAdvisor', '#fff');
		$('header').removeClass('inverted');
		$('.thermometer , .pagination ').removeClass('inverted');
		switchLogoColour('white');
		$('header .branding').removeClass('trans');
		$('header').removeClass('trans');

		

	} 
 
	// Open MENUS
	$('.navigation li a').on('click', function (e) {
		if (!$(this).is(':visible') || $(this).css('opacity') == 0 || !$(this).find('object').is(':visible')) {
			e.preventDefault();
			return false;
		}
	});

	// Mobile dropdown menu
	if ($(window).width() < 768) {

		//$('.menu ul > li:not(.secondary)').prepend('<span class="arrow"></span>');
	}


	// ESC key to close form and menu

	$(document).keyup(function(e) {
		 if (e.keyCode == 27) { // escape key 
			var button = $('.booking-toggle');
			var elem = $('.booking-form') ;
			
			closeBookingForm(elem , button);

			$('.full-menu').removeClass('open');
			$('body').removeClass('menu-open');

			$('header .branding , header .return-x , header .social').removeClass('trans');

			closeModal();

		}

	});

	// Change logo out for mobile 

	if ($(window).width() < 768) {

		var img = $(".home #logo");
		img.attr("data" , "/assets/images/logo-inner.svg").css({"max-width" : "116px" }) ;

	}

  	if ( $(window).width() >= 768 ) {

		var offset = window.innerHeight * .3 ; 

	} ;

	// Increment the sections


	// Show / Hide Logos

	var waypoints = $(' .home section:nth-child(2) ').waypoint({
		  handler: function(direction) {
			var elem = this.element;
			$('header .branding').removeClass('trans');
		  }, offset: '0'
	}) ;

	var waypoints = $(' .home section:first-child ').waypoint({
		  handler: function(direction) {
			var elem = this.element;
			$('header .branding').addClass('trans');
		  }, offset: '-10'
	}) ;

	// Show / Hide Menu items

	$(".collapses > a").on("click", function(event) {
		$(this).find('.mobile-list').slideToggle();
	})
 
	 
	// Function to swap background images
	function swapBg(imageSrc, sharpDiv, originalDiv, section)
	{
	  $('<img/>').attr('src', imageSrc).on('load', function() 
	  {
		sharpDiv.css('background', 'url('+imageSrc+') no-repeat center center');
		
		originalDiv.fadeOut(1200, function()

		{
		  $(this).remove();
		});
		

		$('div.original').css({ "filter": "blur(0)" });
		
		$(this).remove();
	  });
	}


	$( "section.blur-in:first-child" ).each(function( index , element ) {

		var elem = $(this) ;

		var bigSrcBg = elem.find('.backgroundImage').data('src');
		var bgSharp = elem.find('.original');
		var	bgImage = elem.find('.sharp');
		var	loadedSection = elem ; 

		swapBg(bigSrcBg, bgSharp, bgImage, loadedSection);
	
	});


	var waypoints = $(' section.full-screen.blur-in , section.slider.blur-in ').waypoint({
	  handler: function(direction) {
	 
		var elem = $(this.element) ;

		var bigSrcBg = elem.find('.backgroundImage').data('src');
		var bgSharp = elem.find('.original');
		var	bgImage = elem.find('.sharp');
		var	loadedSection = elem ; 

		swapBg(bigSrcBg, bgSharp, bgImage, loadedSection);


		  }, offset: '200px'
	}) ;



	$('form label > input , form label > textarea ').click(function() {
		var input = $(this) ;
		var parent = $(this).parent();

		parent.addClass('selected-child') ;
		parent.siblings().removeClass('selected-child') ;

	});



	// Cancel out / x button

	$('.date-picker .close').click(function() {
		$('.date-picker').toggleClass('open').toggleClass('closed')
	});


	function beginBooking(){


		$( ".modal-target" ).load( "booking/booking.html" );
		$( "body" ).addClass('modal-open') ;

		var button = $('.booking-toggle');
		var elem = $('.booking-form');

		closeBookingForm(elem , button);

		$('header .branding').removeClass('trans');

		
	};


	// Code Modal

	$(".field-group.code button").click(function(){
		$('.field-group.code label').toggleClass('open-modal');
	});
	
	
	// Lock scroll on calendar view
	if ($(window).width() < 1279) {
	    $('.booking-form .comiseo-daterangepicker-triggerbutton').on('click', function(){
		    $('body, html').addClass('noscroll');
		    // $.scrollify.disable();

	    });
	    $('.comiseo-daterangepicker-buttonpanel button').on('click', function(){ 
		    $('body, html').removeClass('noscroll');
		    // $.scrollify.enable();
	    });
	}


	var url = window.location.protocol + "://" + window.location.host + "/" + window.location.pathname;
	var pathArray = window.location.pathname.split('/')	;
	var segment_1 = pathArray[1];
	var segment_2 = pathArray[2];
	if(segment_1 == 'rooms' && segment_2 != '' && segment_2 != undefined){
		//document.getElementsByClassName('booking-toggle')[0].style.visibility = 'hidden';
		document.getElementsByClassName('booking-toggle')[0].classList.add('mobile');
	}


});


// START Image Gallery Configuration
$(document).ready(function() {
	$('.lightbox').magnificPopup({
		type: 'image',
		closeOnContentClick: false,
		closeBtnInside: false,
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
			tPrev: 'Previous',
			tNext: 'Next',
		},
		image: {
			// titleSrc: 'caption',
			titleSrc: function(item) {
            	return `
					<h1 class="mfc-image-title">${item.el.attr('mfc-title')}</h1>
					<p class="mfc-image-caption">${item.el.attr('mfc-caption')}</p>
            	`
            },
			verticalFit: true,
			markup: `
				<div class="mfp-figure">
					<div class="mfp-counter"></div>
					<div class="mfp-img"></div>
					<div class="mfp-bottom-bar">
						<div class="mfp-title"></div>
					</div>
				</div>
			`
		},
		retina: {
			ratio: 1,
			replaceSrc: function(itesm, ratio) {
				return item.src.replace(/\.\w+$/, function(m) { return '@2x' + m; });
			}
		}

	}).click(function() {
		$('.mfp-img').attr('alt', $(this).data('alt'));
	});
});
// END Image Gallery Configuration


function showBookingNotAvailable(event) {
	$('html, body').css({
		overflow: 'hidden',
		height: '100%'
	});

	$.blockUI({
		message: '<div class="modal-wrapper"><p>We apologise for this inconvenience however we are currently experiencing technical issues with our Online Reservations.</p>' +
		'<p>Please call our Hotel directly on <a href="tel:+61865003950">+61 8 6500 3950</a> and we will be able to look after your reservation enquiry.</p>' +
		'<p>Click <a href="/">here</a> to go back to the homepage.</p>' +
		'<span class="button b-close"><span>&times;</span></span></div>',
	});

	return false;
}